import axios from 'axios';
import { queryBuilder } from 'core/helpers/apiHelpers';
import { queryParamsBuilder } from 'core/helpers/queryParamsHelpers';
import axiosInstance from 'core/interceptors/authInterceptor';
import { IMe } from 'core/interfaces/account';
import { IAccountOptions, IAccountsInventoryOptions } from 'core/interfaces/accountApi';

const changePassword = async (email: string) => {
  await axios({
    method: 'post',
    url: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`,
    headers: { 'content-type': 'application/json' },
    data: {
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
      email,
      connection: 'Username-Password-Authentication',
    },
  });
};

const getAccount = async () => {
  return await axiosInstance.get(`/me`);
};

const patchAccount = async ({ language, phoneValue, notifications, picture }: Partial<IMe>) => {
  return await axiosInstance.patch(`/me`, {
    language,
    phoneValue,
    notifications,
    picture,
  });
};

const getAccounts = async (options: IAccountOptions) => {
  const queryParams = queryBuilder(options?.queryParams);

  return await axiosInstance.get(`/accounts/${queryParams}`);
};

const getAccountsListDetail = async (options: IAccountsInventoryOptions) => {
  const queryParams = queryParamsBuilder(options);
  return await axiosInstance.get(`/admin/accounts${queryParams}`);
};

const patchAccountDetailsAdmin = async (user: Partial<IMe>) => {
  return await axiosInstance.patch(`/admin/accounts/${user.accountId}`, {
    firstName: user.firstName,
    lastName: user.lastName,
    role: user.role,
  });
};

const postCommonGroupsAdmin = async (accountIds: string[]) => {
  return await axiosInstance.post(`/groups/shared`, { accountIds });
};

const getAccountsTotalByCompany = async (idCompany: number) => {
  return await axiosInstance.get(`/admin/company/${idCompany}/count/accounts`);
};

const postAccountsImport = async (csv: FormData, companyId: number) => {
  return await axiosInstance.post(`/admin/accounts/import/${companyId}`, csv);
};

const getAccountDetail = async (accountId: string) => {
  return await axiosInstance.get(`/admin/accounts/${accountId}`);
};

const patchAccountAffiliateAdmin = async (user: Partial<IMe>) => {
  const { accountId, firstName, lastName, role, company } = user;
  const companyId = company?.companyId;
  return await axiosInstance.post(`/admin/accounts/${accountId}/affiliate`, {
    firstName,
    lastName,
    role,
    companyId,
  });
};

const postAccountsRevokes = async (accountIds: string[] | string) => {
  return await axiosInstance.post(`/admin/accounts/batch/revoke`, {
    accountIds,
  });
};

const postAccountsActivates = async (accountIds: string[] | string) => {
  return await axiosInstance.post(`/admin/accounts/batch/activate`, {
    accountIds,
  });
};

const postAccountsUnActivate = async (accountIds: string[] | string) => {
  return await axiosInstance.post(`/admin/accounts/batch/unactivate`, {
    accountIds,
  });
};

const postAccountsGivesWebAccess = async (accountIds: string[] | string) => {
  return await axiosInstance.post(`/admin/accounts/batch/give-web-access`, {
    accountIds,
  });
};

const postAccountsPasswordsReset = async (accountIds: string[] | string) => {
  return await axiosInstance.post(`/admin/accounts/batch/reset-password`, {
    accountIds,
  });
};

const postAccountInitiatives = async () => {
  return await axiosInstance.post(`/initiatives/add-to-challenge`);
};

export {
  postAccountsUnActivate,
  patchAccountDetailsAdmin,
  patchAccount,
  getAccountsListDetail,
  changePassword,
  getAccount,
  getAccounts,
  getAccountsTotalByCompany,
  postAccountsImport,
  getAccountDetail,
  postCommonGroupsAdmin,
  patchAccountAffiliateAdmin,
  postAccountsRevokes,
  postAccountsActivates,
  postAccountsPasswordsReset,
  postAccountInitiatives,
  postAccountsGivesWebAccess,
};
