import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setSnackBar } from './snackbarSlice';
import request from 'axios';
import i18next from 'i18next';
import { IResponseError } from '../core/interfaces/status';
import { RequestStatusEnum } from '../core/enums/stateEnum';
import { RootState } from './rootReducer';
import { fetchAllAccounting, postAccountingItem } from '../services/accountingApi';
import {
  IAccountingItem,
  IAccountingListOptions,
  ICreateAccountingItemPayload,
} from '../core/interfaces/accounting';

interface IAcrossCompaniesState {
  accountingItems: IAccountingItem[];
  loading: RequestStatusEnum;
  error: IResponseError;
  currentPage: number;
  total: number;
}

const initialState: IAcrossCompaniesState = {
  accountingItems: [],
  loading: RequestStatusEnum.IDLE,
  error: {} as IResponseError,
  currentPage: 0,
  total: 0,
};

export const fetchAllAccountingThunk = createAsyncThunk(
  'accounting/fetchAllAccountingThunk',
  async (options: IAccountingListOptions, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchAllAccounting(options);
      return response.data;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

export const postAcountingItemThunk = createAsyncThunk(
  'accounting/postAcountingItemThunk',
  async (action: ICreateAccountingItemPayload, { rejectWithValue, dispatch }) => {
    try {
      const response = await postAccountingItem(action);
      dispatch(
        setSnackBar({
          open: true,
          type: 'success',
          message: i18next.t('ACROSS_COMPANIES.ACCOUNTING.SUCCESS_CREATE_ACCOUNTING'),
        })
      );

      return response.data;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

const accountingSlice = createSlice({
  name: 'accounting',
  initialState,
  reducers: {
    resetAccounting: (state: IAcrossCompaniesState) => {
      state.accountingItems = [];
      state.currentPage = 0;
      state.loading = RequestStatusEnum.IDLE;
      state.total = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllAccountingThunk.pending, (state) => {
      state.loading = RequestStatusEnum.PENDING;
    });
    builder.addCase(fetchAllAccountingThunk.fulfilled, (state, action) => {
      state.loading = RequestStatusEnum.FULFILLED;
      state.total = action.payload.total;
      state.currentPage = action.payload.currentPage;
      if (state.currentPage === 0) {
        state.accountingItems = action.payload.items;
      } else {
        state.accountingItems = [...state.accountingItems, ...action.payload.items];
      }
    });
    builder.addCase(fetchAllAccountingThunk.rejected, (state, error) => {
      state.loading = RequestStatusEnum.REJECTED;
      state.error = error.payload as IResponseError;
    });
  },
});

export const { resetAccounting } = accountingSlice.actions;

export const selectAccountingItems = (state: RootState) => state.accountingReducer;

export default accountingSlice.reducer;
