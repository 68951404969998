import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RequestStatusEnum } from '../core/enums/stateEnum';
import { IResponseError } from '../core/interfaces/status';
import { RootState } from './rootReducer';
import request from 'axios';
import { ICompany } from '../core/interfaces/company';
import { fetchCompany, patchCompany } from '../services/companyApi';
import { fetchSpendingTokenByCompany } from '../services/tokenApi';
import { setSnackBar } from './snackbarSlice';
import i18next from 'i18next';
import { ISpendingToken } from '../core/interfaces/token';

interface ICompanyState {
  company: ICompany | null;
  loading: RequestStatusEnum;
  updatingStatus: RequestStatusEnum;
  spendingToken: ISpendingToken | null;
  error: IResponseError | null;
}

export const initialState: ICompanyState = {
  company: null,
  spendingToken: null,
  loading: RequestStatusEnum.IDLE,
  updatingStatus: RequestStatusEnum.IDLE,
  error: null,
};

export const fetchCompanyThunk = createAsyncThunk(
  'company/fetchCompanyThunk',
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchCompany(id);
      return response.data as ICompany;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

export const fetchSpendingCompanyThunk = createAsyncThunk(
  'company/fetchSpendingCompanyThunk',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await fetchSpendingTokenByCompany(id);
      return response.data as ISpendingToken;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

export const patchCompanyPermissionsThunk = createAsyncThunk(
  'account/patchCompanyPermissionsThunk',
  async (data: Partial<ICompany>, { rejectWithValue, dispatch }) => {
    try {
      const response = await patchCompany(data.companyId, data.permissions);
      dispatch(
        setSnackBar({
          open: true,
          type: 'success',
          message: i18next.t('EDIT_PROFILE.SUCCESS_UPDATE_PROFILE'),
        })
      );
      return response.data as ICompany;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    updateCompany: (state: ICompanyState, { payload }: PayloadAction<ICompany>) => {
      state.company = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCompanyThunk.pending, (state) => {
      state.loading = RequestStatusEnum.PENDING;
    });
    builder.addCase(fetchCompanyThunk.fulfilled, (state, action) => {
      state.loading = RequestStatusEnum.FULFILLED;
      state.company = action.payload as ICompany;
    });
    builder.addCase(fetchCompanyThunk.rejected, (state, error) => {
      state.loading = RequestStatusEnum.REJECTED;
      state.error = error.payload as IResponseError;
    });
    builder.addCase(patchCompanyPermissionsThunk.pending, (state) => {
      state.updatingStatus = RequestStatusEnum.PENDING;
    });
    builder.addCase(patchCompanyPermissionsThunk.fulfilled, (state, action) => {
      state.updatingStatus = RequestStatusEnum.FULFILLED;
      state.company = { ...state.company, ...action.payload } as ICompany;
    });
    builder.addCase(patchCompanyPermissionsThunk.rejected, (state, error) => {
      state.updatingStatus = RequestStatusEnum.REJECTED;
      state.error = error.payload as IResponseError;
    });
    builder.addCase(fetchSpendingCompanyThunk.fulfilled, (state, action) => {
      state.loading = RequestStatusEnum.FULFILLED;
      state.spendingToken = action.payload as ISpendingToken;
    });
    builder.addCase(fetchSpendingCompanyThunk.rejected, (state, error) => {
      state.loading = RequestStatusEnum.REJECTED;
      state.error = error.payload as IResponseError;
    });
  },
});

export const { updateCompany } = companySlice.actions;
export const selectCompany = (state: RootState) => state.companyReducer;

export default companySlice.reducer;
