import { CompanyPermissionEnum } from '../core/enums/companyEnum';
import { queryBuilder } from '../core/helpers/apiHelpers';
import axiosInstance from '../core/interceptors/authInterceptor';
import { ICompanyOptions } from '../core/interfaces/company';

const fetchCompanies = async (options: ICompanyOptions) => {
  const queryParams = queryBuilder(options);

  const response = await axiosInstance.get(`/admin/companies${queryParams}`);
  return response.data;
};

const createCompany = async (name: string, domains: string[], picture: string | null) => {
  return await axiosInstance.post('/admin/companies', {
    name,
    domains,
    picture,
  });
};

const fetchCompany = async (companyId: number) => {
  return await axiosInstance.get(`/companies/${companyId}`);
};

const fetchSpendingTokenByType = async (type: 'association' | 'giftCard') => {
  return await axiosInstance.get(`/balance/spending/token?type=${type}`);
};

const patchCompany = async (companyId?: number, permissions?: CompanyPermissionEnum[]) => {
  return await axiosInstance.patch(`/admin/companies/${companyId}`, {
    permissions,
  });
};

export { fetchCompanies, createCompany, fetchCompany, patchCompany, fetchSpendingTokenByType };
