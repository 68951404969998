import { combineReducers } from '@reduxjs/toolkit';

import accountSlice from './accountSlice';
import tokenSlice from './tokenSlice';
import snackbarSlice from './snackbarSlice';
import contactSlice from './contactSlice';
import initiativeSlice from './initiativeSlice';
import initiativesSlice from './initiativesSlice';
import initiativeProjectSlice from './initiativeProjectSlice';
import triggersSlice from './triggersSlice';
import raffleSlice from './raffleSlice';
import accountingSlice from './AccountingSlice';
import companySlice from './companySlice';
import transferTokenSlice from './transferTokenSlice';
import charitiesSlice from './charitiesSlice';
import prosumerSlice from './prosumerSlice';
import prosumerHistorySlice from './prosumerHistorySlice';
import animationSlice from './animationSlice';

const rootReducer = combineReducers({
  animationReducer: animationSlice,
  accountReducer: accountSlice,
  tokenReducer: tokenSlice,
  snackbarReducer: snackbarSlice,
  contactReducer: contactSlice,
  initiativeReducer: initiativeSlice,
  initiativeProjectReducer: initiativeProjectSlice,
  initiativesReducer: initiativesSlice,
  triggersReducer: triggersSlice,
  raffleReducer: raffleSlice,
  accountingReducer: accountingSlice,
  companyReducer: companySlice,
  transferTokenReducer: transferTokenSlice,
  charitiesReducer: charitiesSlice,
  prosumerReducer: prosumerSlice,
  prosumerHistoryReducer: prosumerHistorySlice,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
