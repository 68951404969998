import i18next, { FormatFunction, InitOptions } from 'i18next';
import { LANGUAGES } from 'core/enums/accountEnum';
import frLocale from 'date-fns/locale/fr';
import enLocale from 'date-fns/locale/en-US';
import { format as formatDate, formatRelative, formatDistance, isDate } from 'date-fns';
import { toDate, zonedTimeToUtc } from 'date-fns-tz';
import { DateFormatEnum } from 'core/enums/dateFormatEnum';
const locales = { en: enLocale, fr: frLocale };
type localeType = keyof typeof locales;

export const formatI18Next = ((
  value: Date,
  format = 'medium',
  lng: localeType = i18next.language as localeType
) => {
  if (isDate(value)) {
    const locale = locales[lng];
    if (format === DateFormatEnum.SHORT) return formatDate(value, 'P', { locale });
    if (format === DateFormatEnum.MEDIUM) return formatDate(value, 'PP', { locale });
    if (format === DateFormatEnum.LONG) return formatDate(value, 'PPPP', { locale });
    if (format === DateFormatEnum.RELATIVE) return formatRelative(value, new Date(), { locale });
    if (format === DateFormatEnum.AGO)
      return formatDistance(value, new Date(), {
        locale,
        addSuffix: true,
      });

    return formatDate(value, format, { locale });
  }
  return value;
}) as FormatFunction;

export const parseStringToDate = (value: string) => {
  if (value) {
    const currentTimeZone = i18next.language === LANGUAGES.FR ? 'Europe/Paris' : 'America/New_York';
    const parsedDate = toDate(value);
    return zonedTimeToUtc(parsedDate, currentTimeZone);
  }
};

export const formatLocalizedTime = (value: string) => {
  if (value) {
    const getFullMinutes = (date: Date) => {
      if (date.getMinutes() < 10) {
        return '0' + date.getMinutes();
      }
      return date.getMinutes();
    };
    return `${new Date(value).getHours()?.toString()?.padStart(2, '0')}:${getFullMinutes(
      new Date(value)
    )}`;
  }
};

export const formatDateToISOStringUTC = (value: Date) => {
  if (value) {
    const utcString = value.toUTCString();
    return new Date(utcString).toISOString();
  }
};

export const dateWithTime = (date: Date, time: Date) => {
  /*const ctz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  console.log(ctz);*/
  const dateHours = date.setHours(time.getHours(), time.getMinutes());
  return new Date(dateHours);
};

export const configI18n: InitOptions = {
  debug: true,
  lng: LANGUAGES.EN,
  interpolation: {
    escapeValue: false,
    format: formatI18Next,
  },
  ns: 'common',
};

export const addResourceBundle = (language = 'en') =>
  import(`assets/i18n/${language}/common.json`).then((e) => {
    i18next
      .addResourceBundle(String(language), 'common', {
        ...e,
      })
      .changeLanguage(language);
  });
