import { Auth0Provider } from '@auth0/auth0-react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import store from './state/store';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { configI18n } from './i18nConfig';
import { BrowserRouter } from 'react-router-dom';

/**
 * Polyfill for Array.prototype.at
 * wich is not supported in some old versions of Safari.
 */
if (!Array.prototype.at) {
  Array.prototype.at = function (index) {
    index = Math.trunc(index) || 0;
    if (index < 0) index += this.length;
    if (index < 0) index = 0;
    if (index >= this.length) index = this.length - 1;
    return this[index];
  };
}

const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN as string;
const auth0Url = process.env.REACT_APP_AUTH0_URL as string;
const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID as string;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

i18next.init(configI18n);

root.render(
  <BrowserRouter>
    <I18nextProvider i18n={i18next}>
      <Provider store={store}>
        <Auth0Provider
          domain={auth0Url}
          clientId={auth0ClientId}
          redirectUri={window.location.origin}
          audience={`https://${auth0Domain}/api/v2/`}
          scope="read:current_user update:current_user_metadata"
        >
          <App />
        </Auth0Provider>
      </Provider>
    </I18nextProvider>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
