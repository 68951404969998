import { TriggersTypeEnum } from 'core/enums/triggerEnum';
import axiosInstance from 'core/interceptors/authInterceptor';
import { ITriggerPayload } from 'core/interfaces/trigger';

export const fetchTriggers = async (companyId: number) => {
  return await axiosInstance.get(`/recurringTasks?companyId=${companyId}`);
};

export const patchTrigger = async (data: ITriggerPayload, companyId: number) => {
  return await axiosInstance.patch(`/recurringTask/${companyId}`, data);
};

export const patchActivateTrigger = async (
  triggerType: TriggersTypeEnum,
  activated: boolean,
  companyId: number
) => {
  return await axiosInstance.patch(`/recurringTask/${companyId}/${triggerType}/activate`, {
    activated,
  });
};
