import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import store, { useAppDispatch } from 'state/store';
import { excludedPathnames } from 'core/constants/excludedPathnames';
import { RequestStatusEnum } from 'core/enums/stateEnum';
import { CssBaseline, ThemeProvider } from '@mui/material';
import NavigationProvider from 'core/contexts/navigationProvider/NavigationProvider';
import { Routes } from 'core/routes/Routes';
import './App.css';
import { theme } from 'core/theme';
import { Identify, identify, track } from '@amplitude/analytics-browser';
import { useErrorHandler } from 'core/interceptors/authInterceptorErrorsHandler';
import { useTranslation } from 'react-i18next';
import { setSnackBar } from 'state/snackbarSlice';
import 'i18nConfig';
import SnackBar from 'components/SnackBar';
import PermissionProvider from 'core/contexts/permissionProvider/PermissionProvider';
import { AccountRoleEnum } from 'core/enums/accountEnum';
import { AmplitudeCodeEnum } from 'core/enums/amplitudeEnum';
import { auth0TokenOptions } from 'core/helpers/authHelpers';
import { fetchAccountThunk, selectAccount } from 'state/accountSlice';
import { addResourceBundle } from 'i18nConfig';
import i18next from 'i18next';

function App() {
  const dispatch = useAppDispatch();
  const [t] = useTranslation();
  const { isAuthenticated, getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const { account, loading } = useSelector(selectAccount);
  const { role, permissions } = account;
  const { error: apiError } = useErrorHandler((state) => state);

  useEffect(() => {
    const hasAWafError403 = apiError && typeof apiError['response']['headers'] === 'undefined';
    if (hasAWafError403) {
      dispatch(
        setSnackBar({
          open: true,
          type: 'error',
          message: t('COMMON.SNACKBAR_MESSAGE.ERROR_403'),
        })
      );
      useErrorHandler.setState({ error: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiError]);

  useEffect(() => {
    const storeAccessToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently(auth0TokenOptions);
        const accessTokenObject = JSON.parse(JSON.stringify(accessToken));

        if (isAuthenticated) {
          const accessTokenObjectWithExpirationDate = {
            ...accessTokenObject,
            expiration_date: Date.now() + accessTokenObject.expires_in,
          };

          localStorage.setItem('accessToken', JSON.stringify(accessTokenObjectWithExpirationDate));
          await dispatch(fetchAccountThunk()).then((e) => {
            const language = Object(e.payload).language;
            if (typeof language !== 'undefined') addResourceBundle(language);
          });
        }
      } catch (error) {
        loginWithRedirect();
      }
    };

    storeAccessToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, dispatch, getAccessTokenSilently]);

  useEffect(() => {
    const state = store.getState();

    if (account.accountId?.length && loading === RequestStatusEnum.FULFILLED) {
      track(AmplitudeCodeEnum.APP_LOGIN);
      const identifyObj = new Identify();
      identifyObj.set('accountId', account.accountId);
      identifyObj.set('company', account?.company?.name ?? '');
      identifyObj.set('role', account.role as AccountRoleEnum);
      identify(identifyObj);
    }

    if (
      state.accountReducer.loading !== RequestStatusEnum.FULFILLED ||
      role === AccountRoleEnum.SUPPLIER
    ) {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const { resolvedLanguage } = i18next;

  return typeof resolvedLanguage !== 'undefined' ? (
    <ThemeProvider theme={theme}>
      <PermissionProvider accountPermissions={permissions ?? []}>
        <NavigationProvider excludedPathnames={excludedPathnames}>
          <CssBaseline />
          <Routes />
          <SnackBar />
        </NavigationProvider>
      </PermissionProvider>
    </ThemeProvider >
  ) : null;
}

export default App;
