import {
  IInitiativeOptions,
  IInitiativeParticipationBet,
  IInitiativeReward,
} from 'core/interfaces/initiative';
import { TypeInitiativeEnum } from 'core/enums/initiativeEnum';
import axiosInstance from 'core/interceptors/authInterceptor';
import { IPaginatedListParams } from 'core/interfaces/serviceApi';
import { queryBuilder } from 'core/helpers/apiHelpers';

const createInitiative = async (typology: TypeInitiativeEnum) => {
  const response = await axiosInstance.post('/admin/initiative', { type: typology });
  return response;
};

const fetchInitiatives = async (options: IInitiativeOptions) => {
  const queryOptions: IPaginatedListParams = {
    page: options.page,
    sort: options.sort,
    sortValue: options.sortValue,
    limit: options.limit,
  };

  const queryParams = queryBuilder(queryOptions);
  return await axiosInstance.post(`/initiatives${queryParams}`, {
    query: options.query,
    ...(options.startDate && { startDate: options.startDate }),
    ...(options.endDate && { endDate: options.endDate }),
    ...(options.companyId && { companyId: options.companyId }),
    ...(options.allSenderIds && { allParticipants: options.allSenderIds }),
    ...(options.participations && { participations: options.participations }),
    ...(options.statuses && { statuses: options.statuses }),
  });
};

const participateToInitiative = async (data: IInitiativeParticipationBet, initiativeId: number) => {
  return await axiosInstance.post(`/initiative/participate/${initiativeId}`, data);
};

const estimateInitiative = async (payload: IInitiativeReward[], initiativeId: number) => {
  const response = await axiosInstance.post(`/admin/initiative/estimate/${initiativeId}`, {
    rewards: payload,
  });
  return response.data;
};

export { createInitiative, fetchInitiatives, participateToInitiative, estimateInitiative };
