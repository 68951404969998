import { Navigate, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAccount } from 'state/accountSlice';
import Restricted from '../contexts/permissionProvider/Restricted';
import { AccountRoleEnum, PermissionEnum } from '../enums/accountEnum';
import ProtectedRoute from './routesProtections/ProtectedRoute';
import { Screens } from './Screens';
import DefaultAdminLayout from 'layout/DefaultAdminLayout';

const Routes = () => {
  const { account } = useSelector(selectAccount);
  const { role } = account;

  const routes = useRoutes([
    {
      path: '/',
      children: [
        {
          path: '/',
          element: <Navigate to="/admin/companies" replace />
        },
        { path: '*', element: <Navigate to="/notFound" replace /> },
        { path: Screens.NotFound.path, element: <Screens.NotFound.Component /> },
        { path: Screens.Denied.path, element: <Screens.Denied.Component /> },
        { path: Screens.ErrorCompanyNotKnown.path, element: <Screens.ErrorCompanyNotKnown.Component /> },
        { path: Screens.Revoked.path, element: <Screens.Revoked.Component /> },
        {
          path: Screens.AdminCompanies.path,
          children: [
            {
              index: true,
              element: (
                <ProtectedRoute
                  privilege={AccountRoleEnum.SUPPLIER}
                  role={role?.toUpperCase() as AccountRoleEnum}
                >
                  <Screens.AdminCompanies.Component />
                </ProtectedRoute>
              ),
            },
            {
              path: Screens.CompanyIdTokens.path,
              element: (
                <ProtectedRoute
                  privilege={AccountRoleEnum.SUPPLIER}
                  role={role?.toUpperCase() as AccountRoleEnum}
                >
                  <Screens.CompanyIdTokens.Component />
                </ProtectedRoute>
              ),
            },
            {
              path: Screens.CompanyIdAudiences.path,
              element: (
                <ProtectedRoute
                  privilege={AccountRoleEnum.SUPPLIER}
                  role={role?.toUpperCase() as AccountRoleEnum}
                >
                  <Screens.CompanyIdAudiences.Component />
                </ProtectedRoute>
              ),
              children: [
                {
                  path: Screens.Collaborators.path,
                  element: (
                    <ProtectedRoute
                      privilege={AccountRoleEnum.SUPPLIER}
                      role={role?.toUpperCase() as AccountRoleEnum}
                    >
                      <Screens.Collaborators.Component />
                    </ProtectedRoute>
                  ),
                },
                {
                  path: Screens.Groups.path,
                  element: (
                    <ProtectedRoute
                      privilege={AccountRoleEnum.SUPPLIER}
                      role={role?.toUpperCase() as AccountRoleEnum}
                    >
                      <Screens.Groups.Component />
                    </ProtectedRoute>
                  ),
                },
              ],
            },
            {
              path: Screens.CompanyIdAudiencesGroupsCreate.path,
              element: (
                <ProtectedRoute
                  privilege={AccountRoleEnum.SUPPLIER}
                  role={role?.toUpperCase() as AccountRoleEnum}
                >
                  <Screens.CompanyIdAudiencesGroupsCreate.Component />
                </ProtectedRoute>
              ),
            },
            {
              path: Screens.CompanyIdAudiencesGroupsDetailsId.path,
              element: (
                <ProtectedRoute
                  privilege={AccountRoleEnum.SUPPLIER}
                  role={role?.toUpperCase() as AccountRoleEnum}
                >
                  <Screens.CompanyIdAudiencesGroupsDetailsId.Component />
                </ProtectedRoute>
              ),
            },
            {
              path: Screens.CompanyIdTokenCreate.path,
              element: (
                <ProtectedRoute
                  privilege={AccountRoleEnum.SUPPLIER}
                  role={role?.toUpperCase() as AccountRoleEnum}
                >
                  <Screens.CompanyIdTokenCreate.Component />
                </ProtectedRoute>
              ),
            },
            {
              path: Screens.CompanyIdTriggers.path,
              element: (
                <ProtectedRoute
                  privilege={AccountRoleEnum.SUPPLIER}
                  role={role?.toUpperCase() as AccountRoleEnum}
                >
                  <Screens.CompanyIdTriggers.Component />
                </ProtectedRoute>
              ),
            },
            {
              path: Screens.CompanyIdFunctionalities.path,
              element: (
                <ProtectedRoute
                  privilege={[AccountRoleEnum.SUPPLIER, AccountRoleEnum.ISSUER]}
                  role={role?.toUpperCase() as AccountRoleEnum}
                >
                  <Screens.CompanyIdFunctionalities.Component />
                </ProtectedRoute>
              ),
            },
            {
              path: Screens.CompanyIdInitiatives.path,
              element: (
                <ProtectedRoute
                  privilege={[AccountRoleEnum.SUPPLIER, AccountRoleEnum.ISSUER]}
                  role={role?.toUpperCase() as AccountRoleEnum}
                >
                  <Screens.CompanyIdInitiatives.Component />
                </ProtectedRoute>
              ),
            },
            {
              path: Screens.CompanyIdBirthdays.path,
              element: (
                <ProtectedRoute
                  privilege={[AccountRoleEnum.SUPPLIER, AccountRoleEnum.ISSUER]}
                  role={role?.toUpperCase() as AccountRoleEnum}
                >
                  <Screens.CompanyIdBirthdays.Component />
                </ProtectedRoute>
              ),
            },
            {
              path: Screens.CompanyIdDashboard.path,
              element: (
                <ProtectedRoute
                  privilege={AccountRoleEnum.SUPPLIER}
                  role={role?.toUpperCase() as AccountRoleEnum}
                >
                  <Screens.CompanyIdDashboard.Component />
                </ProtectedRoute>
              ),
            },
            {
              path: Screens.CompanyIdGiftCards.path,
              element: (
                <ProtectedRoute
                  privilege={AccountRoleEnum.SUPPLIER}
                  role={role?.toUpperCase() as AccountRoleEnum}
                >
                  <Screens.CompanyIdGiftCards.Component />
                </ProtectedRoute>
              ),
            },
            {
              path: Screens.CompanyIdLotteries.path,
              element: (
                <ProtectedRoute
                  privilege={[AccountRoleEnum.SUPPLIER, AccountRoleEnum.ISSUER]}
                  role={role?.toUpperCase() as AccountRoleEnum}
                >
                  <Screens.CompanyIdLotteries.Component />
                </ProtectedRoute>
              ),
            },
            {
              path: Screens.CompanyIdCreateChallenge.path,
              element: (
                <ProtectedRoute
                  privilege={[AccountRoleEnum.SUPPLIER, AccountRoleEnum.ISSUER]}
                  role={role?.toUpperCase() as AccountRoleEnum}
                >
                  <Screens.CompanyIdCreateChallenge.Component />
                </ProtectedRoute>
              ),
            },
            {
              path: Screens.CompanyIdProsumer.path,
              element: (
                <ProtectedRoute
                  privilege={AccountRoleEnum.SUPPLIER}
                  role={role?.toUpperCase() as AccountRoleEnum}
                >
                  <Screens.CompanyIdProsumer.Component />
                </ProtectedRoute>
              ),
            },
            {
              path: Screens.CompanyIdCharities.path,
              element: (
                <ProtectedRoute
                  privilege={AccountRoleEnum.SUPPLIER}
                  role={role?.toUpperCase() as AccountRoleEnum}
                >
                  <Screens.CompanyIdCharities.Component />
                </ProtectedRoute>
              ),
            },
            {
              path: Screens.CompanyIdLotteriesLaunchId.path,
              element: (
                <ProtectedRoute
                  privilege={[AccountRoleEnum.SUPPLIER, AccountRoleEnum.ISSUER]}
                  role={role?.toUpperCase() as AccountRoleEnum}
                >
                  <Screens.CompanyIdLotteriesLaunchId.Component />
                </ProtectedRoute>
              ),
            },
            {
              path: Screens.CompanyIdTransfer.path,
              element: (
                <ProtectedRoute
                  privilege={[AccountRoleEnum.SUPPLIER, AccountRoleEnum.ISSUER]}
                  role={role?.toUpperCase() as AccountRoleEnum}
                >
                  <Screens.CompanyIdTransfer.Component />
                </ProtectedRoute>
              ),
            },
            {
              path: Screens.CompanyIdTransferToken.path,
              element: (
                <ProtectedRoute
                  privilege={[AccountRoleEnum.SUPPLIER, AccountRoleEnum.ISSUER]}
                  role={role?.toUpperCase() as AccountRoleEnum}
                >
                  <Screens.CompanyIdTransferToken.Component />
                </ProtectedRoute>
              ),
              children: [
                {
                  path: Screens.CompanyIdTransferTokenCollaborators.path,
                  element: (
                    <ProtectedRoute
                      privilege={AccountRoleEnum.SUPPLIER}
                      role={role?.toUpperCase() as AccountRoleEnum}
                    >
                      <Screens.CompanyIdTransferTokenCollaborators.Component />
                    </ProtectedRoute>
                  ),
                },
                {
                  path: Screens.CompanyIdTransferTokenCollaboratorsGroupsGroupId.path,
                  element: (
                    <ProtectedRoute
                      privilege={AccountRoleEnum.SUPPLIER}
                      role={role?.toUpperCase() as AccountRoleEnum}
                    >
                      <Screens.CompanyIdTransferTokenCollaboratorsGroupsGroupId.Component />
                    </ProtectedRoute>
                  ),
                },
              ],
            },
            {
              path: Screens.CompanyIdIntegration.path,
              element: (
                <ProtectedRoute
                  privilege={[AccountRoleEnum.SUPPLIER, AccountRoleEnum.ISSUER]}
                  role={role?.toUpperCase() as AccountRoleEnum}
                >
                  <Screens.CompanyIdIntegration.Component />
                </ProtectedRoute>
              ),
            },
            {
              path: Screens.CompanyIdCreateProsumer.path,
              element: (
                <ProtectedRoute
                  privilege={AccountRoleEnum.SUPPLIER}
                  role={role?.toUpperCase() as AccountRoleEnum}
                >
                  <Screens.CompanyIdCreateProsumer.Component />
                </ProtectedRoute>
              ),
            },
            {
              path: Screens.CompanyIdUpdateProsumerId.path,
              element: (
                <ProtectedRoute
                  privilege={AccountRoleEnum.SUPPLIER}
                  role={role?.toUpperCase() as AccountRoleEnum}
                >
                  <Screens.CompanyIdUpdateProsumerId.Component />
                </ProtectedRoute>
              ),
            },
            {
              path: Screens.CultureElement.path,
              element: (
                <ProtectedRoute
                  privilege={AccountRoleEnum.SUPPLIER}
                  role={role?.toUpperCase() as AccountRoleEnum}
                >
                  <Screens.CultureElement.Component />
                </ProtectedRoute>
              ),
              children: [
                {
                  path: Screens.CultureElementAborescence.path,
                  element: (
                    <ProtectedRoute
                      privilege={AccountRoleEnum.SUPPLIER}
                      role={role?.toUpperCase() as AccountRoleEnum}
                    >
                      <Screens.CultureElementAborescence.Component />
                    </ProtectedRoute>
                  ),
                },
                {
                  path: Screens.CultureElementEdit.path,
                  element: (
                    <ProtectedRoute
                      privilege={AccountRoleEnum.SUPPLIER}
                      role={role?.toUpperCase() as AccountRoleEnum}
                    >
                      <Screens.CultureElementEdit.Component />
                    </ProtectedRoute>
                  ),
                },
              ],
            },
            {
              path: Screens.ModuleRecognition.path,
              element: (
                <ProtectedRoute
                  privilege={AccountRoleEnum.SUPPLIER}
                  role={role?.toUpperCase() as AccountRoleEnum}
                >
                  <Screens.ModuleRecognition.Component />
                </ProtectedRoute>
              ),
              children: [
                {
                  path: Screens.ModuleRecognitionParams.path,
                  element: (
                    <ProtectedRoute
                      privilege={AccountRoleEnum.SUPPLIER}
                      role={role?.toUpperCase() as AccountRoleEnum}
                    >
                      <Screens.ModuleRecognitionParams.Component />
                    </ProtectedRoute>
                  ),
                },
                {
                  path: Screens.ModuleRecurringTask.path,
                  element: (
                    <ProtectedRoute
                      privilege={AccountRoleEnum.SUPPLIER}
                      role={role?.toUpperCase() as AccountRoleEnum}
                    >
                      <Screens.ModuleRecurringTask.Component />
                    </ProtectedRoute>
                  ),
                },
              ],
            },
            {
              path: Screens.CreateCultureElement.path,
              element: (
                <ProtectedRoute
                  privilege={AccountRoleEnum.SUPPLIER}
                  role={role?.toUpperCase() as AccountRoleEnum}
                >
                  <Screens.CreateCultureElement.Component />
                </ProtectedRoute>
              ),
            },
            {
              path: Screens.UpdateCultureElement.path,
              element: (
                <ProtectedRoute
                  privilege={AccountRoleEnum.SUPPLIER}
                  role={role?.toUpperCase() as AccountRoleEnum}
                >
                  <Screens.UpdateCultureElement.Component />
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: Screens.TokenMintId.path,
          element: (
            <ProtectedRoute>
              <Screens.TokenMintId.Component />
            </ProtectedRoute>
          ),
        },
        {
          path: Screens.AdminProfile.path,
          element: (
            <ProtectedRoute>
              <Screens.AdminProfile.Component />
            </ProtectedRoute>
          ),
        },
        {
          path: Screens.AdminProfileEdit.path,
          element: (
            <ProtectedRoute>
              <Screens.AdminProfileEdit.Component />
            </ProtectedRoute>
          ),
        },
        {
          path: Screens.AdminProfileNotifications.path,
          element: (
            <ProtectedRoute>
              <Screens.AdminProfileNotifications.Component />
            </ProtectedRoute>
          ),
        },
        {
          path: Screens.AdminProfilePassword.path,
          element: (
            <ProtectedRoute>
              <Screens.AdminProfilePassword.Component />
            </ProtectedRoute>
          ),
        },
        {
          path: Screens.AdminProfileLanguage.path,
          element: (
            <ProtectedRoute>
              <Screens.AdminProfileLanguage.Component />
            </ProtectedRoute>
          ),
        },
        {
          path: Screens.AdminHelpImprovement.path,
          element: (
            <ProtectedRoute>
              <Restricted to={[PermissionEnum.HELP_AND_IMPROVEMENT]}>
                <Screens.AdminHelpImprovement.Component />
              </Restricted>
            </ProtectedRoute>
          ),
        },
        {
          path: Screens.AdminSuggestions.path,
          element: (
            <ProtectedRoute>
              <Screens.AdminSuggestions.Component />
            </ProtectedRoute>
          ),
        },
        {
          path: Screens.InitiativeSelect.path,
          element: (
            <ProtectedRoute
              privilege={AccountRoleEnum.SUPPLIER}
              role={role?.toUpperCase() as AccountRoleEnum}
            >
              <Screens.InitiativeSelect.Component />
            </ProtectedRoute>
          ),
          children: [
            {
              path: Screens.InitiativeSelectCompanyId.path,
              element: (
                <ProtectedRoute
                  privilege={AccountRoleEnum.SUPPLIER}
                  role={role?.toUpperCase() as AccountRoleEnum}
                >
                  <Screens.InitiativeSelectCompanyId.Component />
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: Screens.InitiativeCreateTypeId.path,
          element: (
            <ProtectedRoute
              privilege={[AccountRoleEnum.SUPPLIER, AccountRoleEnum.ISSUER]}
              role={role?.toUpperCase() as AccountRoleEnum}
            >
              <Screens.InitiativeCreateTypeId.Component />
            </ProtectedRoute>
          ),
          children: [
            {
              path: Screens.InitiativeCreateTypeIdCompanyId.path,
              element: (
                <ProtectedRoute
                  privilege={AccountRoleEnum.SUPPLIER}
                  role={role?.toUpperCase() as AccountRoleEnum}
                >
                  <Screens.InitiativeCreateTypeIdCompanyId.Component />
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: Screens.AdminAcrossCompaniesAccounting.path,
          element: (
            <ProtectedRoute
              privilege={AccountRoleEnum.SUPPLIER}
              role={role?.toUpperCase() as AccountRoleEnum}
            >
              <Screens.AdminAcrossCompaniesAccounting.Component />
            </ProtectedRoute>
          ),
        },
      ],
      element: <DefaultAdminLayout />
    }
  ]);
  return routes;
};

export { Routes };
