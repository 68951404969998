export enum AmplitudeCodeEnum {
  APP_LOGIN = 'APP_LOGIN',
  USE_GIVE_THANKS = 'USE_GIVE_THANKS',
  USE_GIVE_ENCOURAGE = 'USE_GIVE_ENCOURAGE',
  USE_GIVE_CONGRATS = 'USE_GIVE_CONGRATS',
  USE_GIVE_ROCKET = 'USE_GIVE_ROCKET',
  IMPACT_VOTE_ALL = 'IMPACT_VOTE_ALL',
  DJOY_ROCKET_ORIENTE = 'DJOY_ROCKET_ORIENTE',
  IMPACT_SUGGEST_PROJECT = 'IMPACT_SUGGEST_PROJECT',
  IMPACT_SUGGEST_VOTES = 'IMPACT_SUGGEST_VOTES',
  IMPACT_SUGGEST_ACTIONS = 'IMPACT_SUGGEST_ACTIONS',
  DJOIN_AUTRES = 'DJOIN_AUTRES',
  PROFIL_CLK_BUG = 'PROFIL_CLK_BUG',
  PROFIL_CLK_SUGGEST = 'PROFIL_CLK_SUGGEST',
  IMPACT_VOTE_DETAIL = 'IMPACT_VOTE_DETAIL',
  INITD_FILTER_PASPARTICIPE = 'INITD_FILTER_PASPARTICIPE',
  IMPACT_VOTE_A_VENIR = 'IMPACT_VOTE_A_VENIR',
  IMPACT_VOTE_TERMINE = 'IMPACT_VOTE_TERMINE',
  NAV_USE = 'NAV_USE',
  NAV_HOME = 'NAV_HOME',
  NAV_IMPACT = 'NAV_IMPACT',
  NAV_PROFILE = 'NAV_PROFILE',
  ONBOARD_CLK_IGNORE = 'ONBOARD_CLK_IGNORE',
  ONBOARD_CLK_BACK = 'ONBOARD_CLK_BACK',
  ONBOARD_CLK_NEXT = 'ONBOARD_CLK_NEXT',
  TRANSFER_ERROR = 'TRANSFER_ERROR',
  USE_CARD_ALL = 'USE_CARD_ALL',
  HOME_MY_IMPACT = 'HOME_MY_IMPACT',
  HOME_MY_COMMUNITY = 'HOME_MY_COMMUNITY',
  HOME_POST_TRANSFERT = 'HOME_POST_TRANSFERT',
  HOME_POST_SCROLL = 'HOME_POST_SCROLL',
  HOME_GROUP_SCROLL = 'HOME_GROUP_SCROLL',
  HOME_POST_DIRECT_RECOGNIZE = 'HOME_POST_DIRECT_RECOGNIZE',
  HOME_POST_DIRECT_LOTTERY = 'HOME_POST_DIRECT_LOTTERY',
  HOME_POST_DIRECT_VOTE = 'HOME_POST_DIRECT_VOTE',
  USE_LOTTERY_DETAIL = 'USE_LOTTERY_DETAIL',
  USE_LOTTERY_DETAIL_RETURN = 'USE_LOTTERY_DETAIL_RETURN',
  USE_LOTTERY_DETAIL_INCREASE = 'USE_LOTTERY_DETAIL_INCREASE',
  COMMENT_SCROLL = 'COMMENT_SCROLL',
}
