import { queryBuilder } from 'core/helpers/apiHelpers';
import axiosInstance from 'core/interceptors/authInterceptor';
import { ICompanyRafflePayload } from 'core/interfaces/company';
import { IPaginatedListParams } from 'core/interfaces/serviceApi';
import { IRaffleByCompanyOptions } from 'core/interfaces/raffle';

const fetchAdminRaffleByCompany = async (options: IRaffleByCompanyOptions) => {
  const queryOptions: IPaginatedListParams = {
    page: options.page,
    limit: options.limit,
    sort: options.sort,
    sortValue: options.sortValue,
  };

  const queryParams = queryBuilder(queryOptions);

  return await axiosInstance.post(`/raffle/list${queryParams}`, {
    ...(options.companyId && { companyId: options.companyId }),
    ...(options.gainTypes && { gainTypes: options.gainTypes }),
    statuses: options.statuses,
    query: options.query,
  });
};

const fetchAdminRaffleById = async (raffleId: number) => {
  return await axiosInstance.get(`/raffle/${raffleId}`);
};

const fetchAdminResultsRaffle = async (raffleId: number) => {
  return await axiosInstance.get(`/raffle/winners/${raffleId}`);
};

const postAdminStartRaffle = async (raffleId: number) => {
  return await axiosInstance.post(`/admin/raffle/draw/${raffleId}`);
};

const postAdminCreateRaffle = async (lotteryPayload: ICompanyRafflePayload) => {
  return await axiosInstance.post('/admin/raffle', lotteryPayload);
};

const postAdminRevealWinner = async (raffleWinnerId: number) => {
  return await axiosInstance.post(`/admin/raffle/reveal/${raffleWinnerId}`);
};

export {
  postAdminCreateRaffle,
  fetchAdminRaffleByCompany,
  fetchAdminRaffleById,
  postAdminStartRaffle,
  fetchAdminResultsRaffle,
  postAdminRevealWinner,
};
