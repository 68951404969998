export enum CompanyPermissionEnum {
  MARKETPLACE_PROSUMER = 'MARKETPLACE_PROSUMER',
  CHALLENGE = 'CHALLENGE',
  RECOGNITION = 'RECOGNITION',
  VOTE = 'VOTE',
  BIRTHDAYS = 'BIRTHDAYS',
  CIVIL_BIRTHDAY = 'CIVIL_BIRTHDAY',
  PROFESSIONAL_BIRTHDAY = 'PROFESSIONAL_BIRTHDAY',
  HELP_AND_IMPROVEMENT = 'HELP_AND_IMPROVEMENT',
  MARKETPLACE_ASSOCIATION = 'MARKETPLACE_ASSOCIATION',
  MARKETPLACE_GIFT_CARD = 'MARKETPLACE_GIFT_CARD',
  MINT_PROOF_OF_BEHAVIOR = 'MINT_PROOF_OF_BEHAVIOR',
  RECOGNITION_ADMIN = 'RECOGNITION_ADMIN',
}
