import ProfilePurple from 'assets/pictograms/ProfilePurple.svg';
import PurchasePurple from 'assets/pictograms/PurchasePurple.svg';
import TransferPurple from 'assets/pictograms/TransferPurple.svg';
import CompanyPurple from 'assets/pictograms/CompanyPurple.svg';
import TokenPurple from 'assets/pictograms/TokenPurple.svg';
import Preview from 'assets/pictograms/BarChart.svg';
import VotePurple from 'assets/pictograms/VotePurple.svg';
import BirthDaysPurple from 'assets/pictograms/BirthDaysPurple.svg';
import Functionalities from 'assets/pictograms/Functionalities.svg';
import Collabs from 'assets/pictograms/PeopleFilled.svg';
import Lottery from 'assets/pictograms/Lottery.svg';
import RocketPurple from 'assets/pictograms/RocketPurple.svg';
import Integration from 'assets/pictograms/Integration.svg';
import RecognizePurple from 'assets/pictograms/RecognizePurple.svg';
import MarketplacePurple from 'assets/pictograms/MarketplacePurple.svg';
import { CompanyPermissionEnum } from '../enums/companyEnum';
import { PermissionEnum } from '../enums/accountEnum';

export interface IAdminMenu {
  id: number;
  text: string;
  icon: string;
  link: string;
  permission: CompanyPermissionEnum | CompanyPermissionEnum[] | null;
}
export interface ICompanyMenu {
  id: number;
  text: string;
  icon: string;
  link?: string;
  child?: IAdminMenu[];
}
export interface IIssuerMenu {
  id: number;
  text: string;
  icon: string;
  link: string;
  permission: PermissionEnum | null;
}
export interface IUserMenu {
  id: number;
  text: string;
  icon: string;
  link?: string;
  child?: IIssuerMenu[];
}

export const adminMenu: Omit<IAdminMenu, 'permission'>[] = [
  {
    id: 1,
    text: 'PROFILE.TITLE',
    icon: ProfilePurple,
    link: '/admin/profile',
  },
  {
    id: 2,
    text: 'COLLABORATORS.COMPANIES',
    icon: CompanyPurple,
    link: '/admin/companies',
  },
  // {
  //   id: 3,
  //   text: 'ACROSS_COMPANIES.ENGAGED_ACTIONS.LIST.ACTIONS_LIST',
  //   icon: RecognizePurple,
  //   link: '/admin/across-companies/engaged-actions',
  // },
  {
    id: 4,
    text: 'COLLABORATORS.TABS.TOKENS_TAB',
    icon: TokenPurple,
    link: '',
  },
  {
    id: 5,
    text: 'ACROSS_COMPANIES.ENGAGED_ACTIONS.LIST.ACCOUNTING',
    icon: PurchasePurple,
    link: '/admin/across-companies/accounting',
  },
];
export const companyMenu: ICompanyMenu[] = [
  {
    id: 1,
    text: 'COLLABORATORS.TABS.DASHBOARD_TAB',
    icon: Preview,
    link: '/admin/companies/id/dashboard',
  },
  {
    id: 2,
    text: 'COLLABORATORS.TABS.COLLAB_TAB',
    icon: Collabs,
    link: '/admin/companies/id/audiences/collaborators',
  },
  {
    id: 3,
    text: 'COLLABORATORS.TABS.TOKENS_TAB',
    icon: TokenPurple,
    link: '/admin/companies/id/tokens',
  },
  {
    id: 4,
    text: 'COLLABORATORS.TABS.FUNCTIONALITIES_TAB',
    icon: Functionalities,
    link: '/admin/companies/id/functionalities',
    child: [
      {
        id: 1,
        text: 'COLLABORATORS.TABS.TRANSFER',
        icon: TransferPurple,
        link: '/admin/companies/id/transfer',
        permission: CompanyPermissionEnum.RECOGNITION_ADMIN,
      },
      {
        id: 2,
        text: 'COLLABORATORS.TABS.CULTURE_ELEMENT_TAB',
        icon: CompanyPurple,
        link: '/admin/companies/id/culture-element/edit',
        permission: CompanyPermissionEnum.RECOGNITION,
      },
      {
        id: 3,
        text: 'COLLABORATORS.TABS.INITIATIVES_TAB',
        icon: VotePurple,
        link: '/admin/companies/id/initiatives',
        permission: CompanyPermissionEnum.VOTE,
      },
      {
        id: 4,
        text: 'COLLABORATORS.TABS.LOTTERIES',
        icon: Lottery,
        link: '/admin/companies/id/lotteries',
        permission: CompanyPermissionEnum.CHALLENGE,
      },
      {
        id: 5,
        text: 'COLLABORATORS.FUNCTIONALITIES_TAB.PEER_REWARD_COLLABORATORS.TITLE.PROSUMER',
        icon: MarketplacePurple,
        link: '/admin/companies/id/prosumer',
        permission: CompanyPermissionEnum.MARKETPLACE_PROSUMER,
      },
      {
        id: 6,
        text: 'COLLABORATORS.FUNCTIONALITIES_TAB.PEER_REWARD_COLLABORATORS.TITLE.ASSOCIATION',
        icon: RecognizePurple,
        link: '/admin/companies/id/charities',
        permission: CompanyPermissionEnum.MARKETPLACE_ASSOCIATION,
      },
      {
        id: 7,
        text: 'COLLABORATORS.FUNCTIONALITIES_TAB.PEER_REWARD_COLLABORATORS.TITLE.GIFT',
        icon: PurchasePurple,
        link: '/admin/companies/id/gift-cards',
        permission: CompanyPermissionEnum.MARKETPLACE_GIFT_CARD,
      },
      {
        id: 8,
        text: 'COLLABORATORS.FUNCTIONALITIES_TAB.PEER_RECOGNITION_COLLABORATORS.TITLE.BIRTHDAYS',
        icon: BirthDaysPurple,
        link: '/admin/companies/id/birthdays',
        permission: [
          CompanyPermissionEnum.CIVIL_BIRTHDAY,
          CompanyPermissionEnum.PROFESSIONAL_BIRTHDAY,
        ],
      },
    ],
  },
  {
    id: 5,
    text: 'COLLABORATORS.TABS.TRIGGERS_TAB',
    icon: RocketPurple,
    link: '/admin/companies/id/triggers',
  },
  {
    id: 6,
    text: 'COLLABORATORS.TABS.INTEGRATION_TAB',
    icon: Integration,
    link: '/admin/companies/id/integration',
  },
];

export const userMenu: IUserMenu[] = [
  {
    id: 2,
    text: 'COLLABORATORS.TABS.COLLAB_TAB',
    icon: Collabs,
    link: '/admin/companies/id/audiences/collaborators',
  },

  {
    id: 4,
    text: 'COLLABORATORS.TABS.FUNCTIONALITIES_TAB',
    icon: Functionalities,
    link: '/admin/companies/id/functionalities',
    child: [
      {
        id: 1,
        text: 'COLLABORATORS.TABS.TRANSFER',
        icon: TransferPurple,
        link: '/admin/companies/id/transfer',
        permission: PermissionEnum.RECOGNITION_ADMIN,
      },

      {
        id: 3,
        text: 'COLLABORATORS.TABS.INITIATIVES_TAB',
        icon: VotePurple,
        link: '/admin/companies/id/initiatives',
        permission: PermissionEnum.VOTE,
      },
      {
        id: 4,
        text: 'COLLABORATORS.TABS.LOTTERIES',
        icon: Lottery,
        link: '/admin/companies/id/lotteries',
        permission: PermissionEnum.RAFFLE,
      },
    ],
  },
  {
    id: 6,
    text: 'COLLABORATORS.TABS.INTEGRATION_TAB',
    icon: Integration,
    link: '/admin/companies/id/integration',
  },
];
