import { queryBuilder } from '../core/helpers/apiHelpers';
import axiosInstance from '../core/interceptors/authInterceptor';
import { IAccountOptions } from '../core/interfaces/accountApi';

const fetchProductsProsumerConfiramation = async (
  productOrderCompanyId: string | undefined,
  managerAccountId: string | undefined
) => {
  return await axiosInstance.get(
    `/marketplace/purchase/approve/${productOrderCompanyId}/${managerAccountId}`
  );
};

const fetchAdminAllProductsProsumer = async (options: IAccountOptions) => {
  const queryParams = queryBuilder(options.queryParams);

  return await axiosInstance.post(
    `/admin/marketplace/products/${options.companyId}/product-company/${queryParams}`,
    {
      query: options.query,
      active: options.activated,
    }
  );
};

const fetchAdminProductsProsumerHistory = async (id: string, options: IAccountOptions) => {
  const queryParams = queryBuilder(options.queryParams);
  return await axiosInstance.post(
    `/admin/marketplace/product/product-company/${id}/history/${queryParams}`,
    {
      type: options.type,
    }
  );
};

export {
  fetchProductsProsumerConfiramation,
  fetchAdminAllProductsProsumer,
  fetchAdminProductsProsumerHistory,
};
