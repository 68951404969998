import { queryBuilder } from '../core/helpers/apiHelpers';
import axiosInstance from '../core/interceptors/authInterceptor';
import { IAccountOptions } from '../core/interfaces/accountApi';
import { IBalanceOptions } from '../core/interfaces/serviceApi';
import { ICreateTokenInputForm, IPatchTokenAdminPayload } from '../core/interfaces/token';
import { IVisibility } from 'core/interfaces/peerRecognition';

const fetchTokenCollection = async (options: IBalanceOptions) => {
  const queryParams = queryBuilder(options?.queryParams);

  return await axiosInstance.post(`/balances${queryParams}`, {
    typologies: options.typologies,
    categories: options.categories,
    ...(options.categoryIds && { categoryIds: options.categoryIds }),
    isTransferableInternal: options.isTransferableInternal,
    isTransferableExternal: options.isTransferableExternal,
  });
};

const fetchTokenCompanyCollection = async (options: IBalanceOptions) => {
  const queryParams = queryBuilder(options?.queryParams);

  return await axiosInstance.post(`/admin/balances/company/${options.companyId}${queryParams}`, {
    typologies: options.typologies,
    categories: options.categories,
    ...(options.isTransferableInternal && {
      isTransferableInternal: options.isTransferableInternal,
    }),
  });
};

const fetchCategories = async () => {
  const response = await axiosInstance.get('/token/categories');
  return response.data;
};

const recognitionAdmin = async (
  accountIds: string[],
  tokenId: string,
  quantity: number,
  message: string,
  picture: string | null,
  companyId: number,
  visibility: IVisibility
) => {
  return await axiosInstance.post(`/recognition/admin`, {
    accountIds,
    tokenId,
    quantity,
    message,
    picture,
    companyId,
    visibility,
  });
};

const fetchAllTokens = async (options: IBalanceOptions) => {
  const queryParams = queryBuilder(options?.queryParams);

  return await axiosInstance.post(`/admin/tokens${queryParams}`, {
    typologies: options.typologies,
    categories: options.categories,
    companyIds: options.companyIds,
    companyId: options.companyId,
    query: options.query,
  });
};

const fetchBalanceAutocomplete = async (options: IAccountOptions, typologies: string[] = []) => {
  const queryParams = queryBuilder(options?.queryParams);

  return await axiosInstance.post(`/balances/minify/company/${options.companyId}/${queryParams}`, {
    query: options.query,
    typologies,
  });
};

const fetchSpendingTokenByCompany = async (companyId: number) => {
  return await axiosInstance.get(`/token/spending/${companyId}`);
};

const fetchAdminCategoriesByBadge = async (companyId: number) => {
  const response = await axiosInstance.get(`/admin/token/badge/categories/${companyId}`);
  return response.data;
};

const fetchAminBadges = async (companyId: number, categoryId: number) => {
  const response = await axiosInstance.get(`/admin/token/badge/${categoryId}/${companyId}`);
  return response.data;
};

const postAdminCreateToken = async (data: ICreateTokenInputForm, companyId: number) => {
  return await axiosInstance.post(`/admin/token`, {
    companyId,
    categoryId: data.categoryId,
    tokenType: data.tokenType,
    name: data.name,
    description: data.description,
    isTransferableInternal: data.isTransferableInternal,
    isTransferableExternal: data.isTransferableExternal,
    existOnChain: data.existOnChain,
    initialSupply: data.maxSupply,
    toMint: data.nbTokens,
    picture: data.picture,
    ...(data.stepLevels && { stepLevels: data.stepLevels }),
    ...(data.stepLevel && { stepLevel: data.stepLevel }),
  });
};

const postAdminMintToken = async (tokenId: string, quantity: number) => {
  return await axiosInstance.post(`/admin/token/mint/${tokenId}`, {
    quantity,
  });
};

const fetchSpendingToken = async () => {
  return await axiosInstance.get(`/balance/spending/token?type=giftCard`);
};

const fetchAdminTokenSupplierById = async (tokenId: string | undefined) => {
  return await axiosInstance.get(`/admin/token/${tokenId}`);
};

const patchAdminToken = async (tokenId: string, data: IPatchTokenAdminPayload) => {
  return await axiosInstance.patch(`/admin/token/${tokenId}`, data);
};

export {
  recognitionAdmin,
  fetchAllTokens,
  fetchBalanceAutocomplete,
  fetchCategories,
  fetchAdminCategoriesByBadge,
  fetchSpendingToken,
  fetchSpendingTokenByCompany,
  fetchTokenCollection,
  fetchTokenCompanyCollection,
  fetchAminBadges,
  postAdminCreateToken,
  postAdminMintToken,
  fetchAdminTokenSupplierById,
  patchAdminToken,
};
