import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setSnackBar } from './snackbarSlice';
import i18next from 'i18next';
import { IResponseError } from '../core/interfaces/status';
import {
  fetchAdminRaffleByCompany,
  postAdminRevealWinner,
  postAdminStartRaffle,
} from '../services/raffleApi';
import { RequestStatusEnum } from '../core/enums/stateEnum';
import request from 'axios';
import { RootState } from './rootReducer';
import { IRaffle, IRaffleByCompanyOptions } from '../core/interfaces/raffle';

interface IRaffleState {
  items: IRaffle[];
  total: number;
  currentPage: number;
  error: IResponseError;
  loading: RequestStatusEnum;
}

const initialState: IRaffleState = {
  items: [],
  total: 0,
  currentPage: 0,
  error: {} as IResponseError,
  loading: RequestStatusEnum.IDLE,
};

export const fetchAdminRaffleByCompanyThunk = createAsyncThunk(
  'raffle/fetchAdminRaffleByCompanyThunk',
  async (options: IRaffleByCompanyOptions, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchAdminRaffleByCompany(options);

      return response.data;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

export const postAdminStartRaffleThunk = createAsyncThunk(
  'raffle/postAdminStartRaffleThunk',
  async (raffleId: number, { rejectWithValue, dispatch }) => {
    try {
      const response = await postAdminStartRaffle(raffleId);

      return response.data;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

export const postAdminRevealWinnerRaffleThunk = createAsyncThunk(
  'raffle/postAdminRevealWinnerRaffleThunk',
  async (raffleWinnerId: number, { rejectWithValue, dispatch }) => {
    try {
      const response = await postAdminRevealWinner(raffleWinnerId);

      return response.data;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

const sortRaffleByStatus = (items: IRaffle[]) => {
  return items.sort((a: IRaffle, b: IRaffle) => {
    if (a.status === 'IN_PROGRESS') return -1;
    if (b.status === 'IN_PROGRESS') return 1;
    if (a.status === 'VALIDATED') return -1;
    if (b.status === 'VALIDATED') return 1;

    return 0;
  });
};

const raffleSlice = createSlice({
  name: 'raffle',
  initialState,
  reducers: {
    resetRaffles: (state: IRaffleState) => {
      state.items = [];
      state.total = 0;
      state.currentPage = 0;
      state.loading = RequestStatusEnum.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdminRaffleByCompanyThunk.pending, (state) => {
      state.loading = RequestStatusEnum.PENDING;
    });
    builder.addCase(fetchAdminRaffleByCompanyThunk.fulfilled, (state, action) => {
      state.loading = RequestStatusEnum.FULFILLED;
      state.total = action.payload.total;
      state.currentPage = action.payload.currentPage;
      if (state.currentPage === 0) {
        state.items = sortRaffleByStatus(action.payload.items);
      } else {
        state.items = [...state.items, ...sortRaffleByStatus(action.payload.items)];
      }
    });
    builder.addCase(fetchAdminRaffleByCompanyThunk.rejected, (state, error) => {
      state.loading = RequestStatusEnum.REJECTED;
      state.error = error.payload as IResponseError;
    });

    builder.addCase(postAdminStartRaffleThunk.pending, (state) => {
      state.loading = RequestStatusEnum.PENDING;
    });
    builder.addCase(postAdminStartRaffleThunk.fulfilled, (state, action) => {
      state.loading = RequestStatusEnum.FULFILLED;
      state.total = action.payload.total;
      state.currentPage = action.payload.currentPage;
      state.items = action.payload.items;
    });
    builder.addCase(postAdminStartRaffleThunk.rejected, (state, error) => {
      state.loading = RequestStatusEnum.REJECTED;
      state.error = error.payload as IResponseError;
    });
    builder.addCase(postAdminRevealWinnerRaffleThunk.pending, (state) => {
      state.loading = RequestStatusEnum.PENDING;
    });
    builder.addCase(postAdminRevealWinnerRaffleThunk.fulfilled, (state, action) => {
      state.loading = RequestStatusEnum.FULFILLED;
      state.total = action.payload.total;
      state.currentPage = action.payload.currentPage;
      state.items = action.payload.items;
    });
    builder.addCase(postAdminRevealWinnerRaffleThunk.rejected, (state, error) => {
      state.loading = RequestStatusEnum.REJECTED;
      state.error = error.payload as IResponseError;
    });
  },
});

export const { resetRaffles } = raffleSlice.actions;

export const selectRaffle = (state: RootState) => state.raffleReducer;

export default raffleSlice.reducer;
