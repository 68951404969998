import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RequestStatusEnum } from '../core/enums/stateEnum';
import { IResponseError } from '../core/interfaces/status';
import { RootState } from './rootReducer';
import { ITriggerPayload, ITriggers } from '../core/interfaces/trigger';
import { fetchTriggers, patchActivateTrigger, patchTrigger } from '../services/triggerApi';
import { triggersDefaultValues } from '../core/constants/triggers';
import { TriggersTypeEnum } from '../core/enums/triggerEnum';
import request from 'axios';
import i18next from 'i18next';
import { setSnackBar } from './snackbarSlice';

interface ITriggersState {
  items: ITriggers | null;
  loading: RequestStatusEnum;
}

export const fetchTriggersThunk = createAsyncThunk(
  'trigger/fetchTriggersThunk',
  async (companyId: number, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchTriggers(companyId);
      return response.data;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

export const patchTriggerThunk = createAsyncThunk(
  'trigger/patchTriggerThunk',
  async (data: { trigger: ITriggerPayload; companyId: number }, { rejectWithValue, dispatch }) => {
    try {
      const response = await patchTrigger(data.trigger, data.companyId);
      return response.data;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

export const patchActivateTriggerThunk = createAsyncThunk(
  'trigger/patchActivateTriggerThunk',
  async (
    data: { triggerType: TriggersTypeEnum; activated: boolean; companyId: number },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await patchActivateTrigger(data.triggerType, data.activated, data.companyId);
      return response.data;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

const initialState: ITriggersState = {
  items: null,
  loading: RequestStatusEnum.IDLE,
};

const triggersSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    resetTriggers: (state: ITriggersState) => {
      state.items = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTriggersThunk.pending, (state) => {
      state.loading = RequestStatusEnum.PENDING;
    });
    builder.addCase(fetchTriggersThunk.fulfilled, (state, action) => {
      state.loading = RequestStatusEnum.FULFILLED;
      state.items = action.payload;
    });
    builder.addCase(fetchTriggersThunk.rejected, (state) => {
      state.items = triggersDefaultValues;
      state.loading = RequestStatusEnum.REJECTED;
    });
    builder.addCase(patchTriggerThunk.pending, (state) => {
      state.loading = RequestStatusEnum.PENDING;
    });
    builder.addCase(patchTriggerThunk.fulfilled, (state, action) => {
      state.loading = RequestStatusEnum.FULFILLED;
      state.items = action.payload as ITriggers;
    });
    builder.addCase(patchTriggerThunk.rejected, (state) => {
      state.loading = RequestStatusEnum.REJECTED;
    });
    builder.addCase(patchActivateTriggerThunk.pending, (state) => {
      state.loading = RequestStatusEnum.PENDING;
    });
    builder.addCase(patchActivateTriggerThunk.fulfilled, (state, action) => {
      state.loading = RequestStatusEnum.FULFILLED;
      state.items = action.payload as ITriggers;
    });
    builder.addCase(patchActivateTriggerThunk.rejected, (state) => {
      state.loading = RequestStatusEnum.REJECTED;
    });
  },
});
export const { resetTriggers } = triggersSlice.actions;
export const selectTriggers = (state: RootState) => state.triggersReducer;

export default triggersSlice.reducer;
