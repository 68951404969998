import { queryBuilder } from 'core/helpers/apiHelpers';
import axiosInstance from 'core/interceptors/authInterceptor';
import { IRewardOptions } from 'core/interfaces/transaction';

const fetchAdminReward = async (options: IRewardOptions) => {
  const queryParams = queryBuilder(options);

  return await axiosInstance.post(
    `/admin/transactions/transfer-admin-company/${options.companyId}${queryParams}`
  );
};

export { fetchAdminReward };
