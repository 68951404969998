import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { CSSObject, Theme, styled, useTheme } from '@mui/material/styles';
import { useContext, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import packageJson from '../../../../package.json';
import DjobLogoHeader from 'assets/images/DjobLogoHeader.svg';
import NavigationContext from 'core/contexts/navigationProvider/NavigationContext';
import { useTranslation } from 'react-i18next';
import { adminMenu } from 'core/constants/menu';

const drawerWidth = 299;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerAdmin = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    position: 'relative',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  })
);
const DrawerDesktop = () => {
  const theme = useTheme();
  const { menuIsShown } = useContext(NavigationContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [t] = useTranslation();
  const [open, setOpen] = useState(true);

  if (!menuIsShown(pathname)) return null;

  return (
    <DrawerAdmin variant="permanent" open={open}>
      <Stack justifyContent="center" alignItems="center" height="65px">
        <img src={DjobLogoHeader} style={{ padding: '5px 0px' }} width="40px" />
        <Typography
          sx={{
            background: `-webkit-linear-gradient(70.7deg, #3A07BD 7.12%, ${theme.palette.primary.main} 42.71%, #7858ED 75.64%, #9A7EFE 109.1%)`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            fontSize: '10px',
            marginBottom: '2px',
          }}
        >
          {process.env.NODE_ENV !== 'production' && packageJson.version}
        </Typography>
      </Stack>
      <Divider />
      <List sx={{ my: 2 }}>
        {adminMenu.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: 'block' }}>
            <NavLink
              className={(navData) => (navData.isActive ? 'active-link' : 'normal-link')}
              to={item.link}
              style={{ textDecoration: 'none' }}
            >
              <ListItemButton
                onClick={() => {
                  navigate(item.link);
                }}
                sx={{
                  minHeight: 60,
                  justifyContent: open ? 'initial' : 'center',
                  px: 3.5,
                  background: 'inherit',
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <img src={item.icon} width="21px" height="21px" />
                </ListItemIcon>
                <ListItemText
                  primary={t(item.text)}
                  sx={{
                    opacity: open ? 1 : 0,
                    color: theme.palette.text.secondary,
                    fontFamily: 'Plus Jakarta Sans',
                    fontWeight: 600,
                  }}
                />
              </ListItemButton>
            </NavLink>
          </ListItem>
        ))}
      </List>
      <Stack sx={{ position: 'absolute', bottom: 0, right: 0 }} width={'100%'}>
        <Divider />
        <DrawerHeader>
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
      </Stack>
    </DrawerAdmin>
  )
};

export default DrawerDesktop;
