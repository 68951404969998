import { queryParamsEnum } from '../core/enums/queryParamsEnum';
import { queryBuilder } from '../core/helpers/apiHelpers';
import axiosInstance from '../core/interceptors/authInterceptor';
import { IAssociationSortFilters, ICharitiesOptions } from '../core/interfaces/charity';
import { IPaginatedListParams } from '../core/interfaces/serviceApi';

const fetchCharities = async (options: ICharitiesOptions) => {
  const queryOptions: IPaginatedListParams = {
    page: options.page,
    limit: options.limit,
    sort: options.sort,
    sortValue: options.sortValue,
  };

  const queryParams = queryBuilder(queryOptions);

  return await axiosInstance.post(`/marketplace/products/charitips/charities/${queryParams}`, {
    ...(options.goals && { goals: options.goals }),
  });
};

const fetchCharitiesAdmin = async (options: IAssociationSortFilters, companyId: number) => {
  const queryOptions: IPaginatedListParams = {
    page: options.page,
    sort: options.sort,
    sortValue: queryParamsEnum.NAME,
    limit: options.limit,
  };
  const queryParams = queryBuilder(queryOptions);

  return await axiosInstance.post(
    `/admin/marketplace/products/charitips/${companyId}/charities/all${queryParams}`,
    {
      goals: options.goals,
      ...(options.isActive === true && { isActive: options.isActive }),
    }
  );
};

const patchCharitiesAdmin = async (companyId: number, charityIds: string[]) => {
  return await axiosInstance.patch(`/admin/marketplace/products/charitips/${companyId}`, {
    charityIds,
  });
};

const validateCharityDonation = async (charityId: string, amount: number) => {
  return await axiosInstance.post(`/admin/marketplace/purchase/association/${charityId}`, {
    amount,
  });
};

const validateCharityModification = async (companyId: number) => {
  return await axiosInstance.post(`/admin/marketplace/products/charitips/${companyId}/deactivate`);
};

export {
  fetchCharities,
  patchCharitiesAdmin,
  fetchCharitiesAdmin,
  validateCharityDonation,
  validateCharityModification,
};
