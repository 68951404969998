declare module '@mui/material/styles' {
  interface Palette {
    background: {
      default: string;
      paper: string;
    };
    purple: {
      lightPurple: string;
      extraLightPurple: string;
      backdropPurple: string;
    };
    commonPalette: {
      white: string;
      contrastWhite: string;
      black: string;
      whiteFont: string;
      darkBeige: string;
      lightBeige: string;
      boxShadow: string;
      backgroundXtraLight: string;
      mediumFont: string;
      backgroundMedium: string;
    };
    gradient: {
      main: string;
      button: string;
      newButton: string;
      linearSpecial: string;
      redGradient: string;
      linearEffect: string;
      djobazing: string;
      gaugeRed: string;
      gaugeEnd: string;
      gaugeWait: string;
      gaugeDone: string;
      gradientGreen: string;
      emoji: string;
      number: string;
      successAnim: string;
      failAnim: string;
      spinnerGreen: string;
      spinnerRose: string;
    };
  }
}
const palette = {
  primary: {
    main: '#532BE3',
    light: '#CBBFF7',
    dark: '#100066',
    contrastText: '#FDFDFE',
  },
  secondary: {
    main: '#B8B7C4',
    light: '#DCDCE3',
    dark: '#7D7A93',
  },
  success: {
    main: '#78DAA2',
    light: '#E2EFDD',
    dark: '#3FA169',
    contrastText: '#FDFDFE',
  },
  error: {
    main: '#F8BBD0',
    light: '#FCE4EC',
    dark: '#FF3E5B',
    contrastText: '#FDFDFE',
  },
  text: {
    primary: '#100066',
    secondary: '#2600AF',
    disabled: '#7D7A93',
  },
  background: {
    default: '#FFFFFF',
    paper: '#FFFFFF',
  },
  purple: {
    lightPurple: '#EAE5FC',
    extraLightPurple: '#EAE5FCCC',
    backdropPurple: '#CBBFF7CC',
  },
  commonPalette: {
    white: '#FFFFFF',
    contrastWhite: '#FAFAFB',
    black: '#000000',
    whiteFont: '#FDFDFE',
    darkBeige: '#F6E8D4',
    lightBeige: '#FFF7ED',
    boxShadow: '2px 2px 8px 0px rgba(38, 0, 175, 0.15) !important',
    backgroundXtraLight: '#F8F7FE',
    mediumFont: '#2600AF',
    backgroundMedium: '#CBBFF7',
  },
  gradient: {
    main: 'linear-gradient(90deg, #3A07BD 0%, #532BE3 34.90%, #7858ED 67.19%, #9A7EFE 100%)',
    button:
      'linear-gradient(12.27deg, #3A07BD 9.12%, #532BE3 35.12%, #7858ED 59.18%, #9A7EFE 83.62%)',
    newButton: 'linear-gradient(180deg, rgba(83, 43, 227, 0.50) 0%, #532BE3 100%)',
    linearSpecial:
      'linear-gradient(68.21deg, #5120CB 11.28%, #532BE3 27.6%, #FF5471 75.25%, #F5279C 89.02%)',
    redGradient:
      'linear-gradient(68.21deg, #FF3E5B 11.28%, #FF3E5B 19.38%, #FF5471 30.71%, #F5279C 62.3%)',
    linearEffect:
      'linear-gradient(193.3deg, #FD2949 9.01%, #FF3E5B 29.66%, #FF5471 54.92%, #F5279C 96.95%)',
    djobazing: 'linear-gradient(0deg, #2600AF 16.22%, rgba(38, 0, 175, 0.53) 100%)',
    gaugeRed:
      'linear-gradient(0.1deg, rgba(0, 0, 0, 0.14) 35.85%, rgba(0, 0, 0, 0) 53.25%), linear-gradient(90deg, rgba(0, 0, 0, 0.14) 0%, rgba(0, 0, 0, 0.0321047) 8.79%, rgba(0, 0, 0, 0) 18.39%), linear-gradient(151.75deg, #F25CA4 13.89%, #F23B5C 141.28%)',
    gaugeEnd:
      'linear-gradient(28.3deg, rgba(0, 0, 0, 0.14) 22.5%, rgba(0, 0, 0, 0) 32.5%), linear-gradient(90deg, rgba(0, 0, 0, 0.14) 0%, rgba(0, 0, 0, 0.0321047) 8.79%, rgba(0, 0, 0, 0) 18.39%), linear-gradient(151.75deg, #F25CA4 13.89%, #F23B5C 141.28%)',
    gaugeWait:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.28) 38.75%, rgba(255, 255, 255, 0) 54.95%), linear-gradient(180deg, #411F97 0%, #170751 100%)',
    gaugeDone:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.28) 38.75%, rgba(255, 255, 255, 0) 54.95%), linear-gradient(180deg, #78DAA2 0%, #5CA27A 100%)',
    gradientGreen:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.28) 38.75%, rgba(255, 255, 255, 0) 54.95%), linear-gradient(261.09deg, #63CE91 -10.81%, #326F4C 72.19%)',
    emoji:
      'linear-gradient(1.6deg, rgba(255, 255, 255, 0.55) -10.71%, rgba(255, 255, 255, 0.28) 38.28%, rgba(255, 255, 255, 0) 52.64%), linear-gradient(180deg, #532BE3 0%, #5D31FF 100%)',
    number: 'linear-gradient(251.08deg, #F43173 37.72%, #BA1765 79%)',
    successAnim: 'linear-gradient(10.03deg, #78DAA2 17.33%, rgba(200, 255, 223, 0.9) 56.96%)',
    failAnim: 'linear-gradient(195.95deg, #F43173 56.09%, #BA1765 81.35%)',
    spinnerGreen:
      'conic-gradient(from 7.97deg at 50% 50%, #8FE8B6 -147.81deg, #67C991 5.24deg, rgba(129, 221, 169, 0) 74.82deg, rgba(143, 232, 182, 0) 115.16deg, #8FE8B6 212.19deg, #67C991 365.24deg)',
    spinnerRose:
      'conic-gradient(from 255.96deg at 52% 49%, #F43173 -41.75deg, #BA1765 100.28deg, rgba(255, 62, 91, 0) 189.48deg, #F43173 318.25deg, #BA1765 460.28deg)',
  },
};
export const paletteOptions = {
  primary: palette.primary,
  secondary: palette.secondary,
  success: palette.success,
  error: palette.error,
  text: palette.text,
  background: palette.background,
  purple: palette.purple,
  commonPalette: palette.commonPalette,
  gradient: palette.gradient,
};
