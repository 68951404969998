import {
  DisplayStatusInitiativeEnum,
  DisplayTypeInitiativeEnum,
  InitiativeGainsExpensesStatusEnum,
  InitiativeSenderEnum,
  LevelRuleEnum,
  SpendingRuleTypeEnum,
  RewardsReasonEnum,
  StatusInitiativeEnum,
  TokenOriginEnum,
  TypeInitiativeEnum,
} from '../enums/initiativeEnum';
import { queryParamsEnum } from '../enums/queryParamsEnum';
import { ISortCriteria } from '../interfaces/balance';
import { CodeLabel } from '../interfaces/codeLabel';
import {
  IActor,
  IInitiative,
  IInitiativeEstimationsTable,
  ILevelRule,
  ISpendingRuleType,
  LevelRuleValue,
  SpendingTypeValue,
} from '../interfaces/initiative';
import { getUrlWithCdn } from '../helpers/pictureHelpers';

export const selectInitiativeStatus = [
  {
    label: DisplayStatusInitiativeEnum.DRAFT,
    code: StatusInitiativeEnum.DRAFT,
  },
  {
    label: DisplayStatusInitiativeEnum.IN_PROGRESS,
    code: StatusInitiativeEnum.IN_PROGRESS,
  },
  {
    label: DisplayStatusInitiativeEnum.VALIDATED,
    code: StatusInitiativeEnum.VALIDATED,
  },
  {
    label: DisplayStatusInitiativeEnum.CLOSED,
    code: StatusInitiativeEnum.CLOSED,
  },
];

export const selectInitiativeType: CodeLabel[] = [
  {
    label: DisplayTypeInitiativeEnum.TRIGGER,
    code: TypeInitiativeEnum.TRIGGER,
  },
  {
    label: DisplayTypeInitiativeEnum.CHALLENGE_CANDIDATE as string,
    code: TypeInitiativeEnum.CHALLENGE_CANDIDATE as string,
  },
  {
    label: DisplayTypeInitiativeEnum.DECLARATIVE,
    code: TypeInitiativeEnum.DECLARATIVE,
  },
  {
    label: DisplayTypeInitiativeEnum.CHALLENGE_PROJECT as string,
    code: TypeInitiativeEnum.CHALLENGE_PROJECT as string,
  },
];

export const initiativeSteps: CodeLabel[] = [
  {
    label: 'CREATE_INITIATIVE.FORM.STEPS.ONE',
    code: 0,
  },
  {
    label: 'CREATE_INITIATIVE.FORM.STEPS.TWO',
    code: 1,
  },
  {
    label: 'CREATE_INITIATIVE.FORM.STEPS.THREE',
    code: 2,
  },
  {
    label: 'CREATE_INITIATIVE.FORM.STEPS.FOUR',
    code: 3,
  },
  {
    label: 'CREATE_INITIATIVE.FORM.STEPS.FIVE',
    code: 4,
  },
];

export const tokenOriginList: CodeLabel[] = [
  {
    label: 'CREATE_INITIATIVE.FORM.BET_RULES.ORIGIN_INITIATIVE_PORTFOLIO',
    code: TokenOriginEnum.INITIATIVE_PORTFOLIO,
  },
  {
    label: 'CREATE_INITIATIVE.FORM.BET_RULES.ORIGIN_EMPLOYEE_PORTFOLIO',
    code: TokenOriginEnum.EMPLOYEE_PORTFOLIO,
  },
];

export const mintTypeList: CodeLabel[] = [
  {
    label: 'CREATE_INITIATIVE.FORM.BET_RULES.FIXE',
    code: SpendingRuleTypeEnum.FIXE,
  },
  {
    label: 'CREATE_INITIATIVE.FORM.BET_RULES.VARIABLE',
    code: SpendingRuleTypeEnum.VARIABLE,
  },
];

export const mintTypeValuesList: Record<SpendingTypeValue, ISpendingRuleType> = {
  FIXE_VALUE: {
    origin: SpendingRuleTypeEnum.FIXE,
    value: 1,
    active: true,
  },
  MIN_VARIABLE: {
    origin: SpendingRuleTypeEnum.VARIABLE,
    value: 0,
    active: false,
  },
  MAX_VARIABLE: {
    origin: SpendingRuleTypeEnum.VARIABLE,
    value: 1,
    active: false,
  },
};

export const mintLevelValues: Record<LevelRuleValue, ILevelRule> = {
  LEVEL_THREE: {
    label: 'égal ou supérieur',
    badgeLevel: 'Niv. 3',
    level: LevelRuleEnum.LEVEL_THREE,
    quantity: 1,
    tokenId: null,
  },
  LEVEL_TWO: {
    label: 'égal',
    badgeLevel: 'Niv. 2',
    level: LevelRuleEnum.LEVEL_TWO,
    quantity: 1,
    tokenId: null,
  },
  LEVEL_ONE: {
    label: 'égal',
    badgeLevel: 'Niv. 1',
    level: LevelRuleEnum.LEVEL_ONE,
    quantity: 1,
    tokenId: null,
  },
};

export const initiativeInitialState = {
  initiativeId: null,
  initiativeType: TypeInitiativeEnum.CHALLENGE_CANDIDATE,
  company: null,
  category: null,
  picture: null,
  name: '',
  startDate: undefined,
  endDate: undefined,
  description: '',
  status: StatusInitiativeEnum.DRAFT,
  nbSenders: null,
  nbReceivers: null,
  spendingRule: {
    token: null,
    origin: TokenOriginEnum.EMPLOYEE_PORTFOLIO,
    quantity: 1,
    min: null,
    max: null,
  },
  levelRule: {
    token: {
      tokenId: null,
      name: null,
      picture: null,
      stepLevels: [],
    },
    levels: [
      {
        stepLevel: null,
        multiplier: 1,
      },
      {
        stepLevel: null,
        multiplier: 1,
      },
      {
        stepLevel: null,
        multiplier: 1,
      },
      {
        stepLevel: null,
        multiplier: 1,
      },
    ],
  },
  rewards: [
    {
      gain: {
        token: null,
        quantity: 1,
      },
      speedStartDate: null,
      speedEndDate: null,
      type: RewardsReasonEnum.RANKING,
      quantityRequired: 1,
    },
    {
      gain: {
        token: {
          tokenId: null,
          name: null,
          category: null,
          stepLevels: [],
        },
        quantity: 1,
      },
      speedStartDate: null,
      speedEndDate: null,
      type: RewardsReasonEnum.PARTICIPATION,
      quantityRequired: 1,
    },
  ],
  participants: [] as IActor[],
  candidates: [] as IActor[],
  actorsInformation: {
    allCandidates: false,
    allParticipants: false,
    nbCandidates: 0,
    nbParticipants: 0,
  },
} as IInitiative;

export const receiverRewardRaison = [
  RewardsReasonEnum.PARTICIPATION,
  RewardsReasonEnum.SPEED,
] as string[];

export const ESTIMATION_TABLE_FIXED_ROWS = [
  'initiativeDetails',
  'maxDistribution',
  'minBet',
  'debitQuantity',
  '',
  'companyBalance',
  'currentBalance',
  'initiativeDebit',
  'forecastBalance',
];

export const ESTIMATION_TABLE: IInitiativeEstimationsTable = {
  rows: [],
  columns: [{ tokenId: '', value: '', name: null }],
  displayEstimation: false,
  status: InitiativeGainsExpensesStatusEnum.INVALID,
  tokens: [],
};

export const SORT_VALUES_FOR_ON_PROGRESS_INITIATIVES: ISortCriteria[] = [
  {
    value: queryParamsEnum.DESC,
    label: 'INITIATIVES.SORT.DATE_DESC',
    type: queryParamsEnum.CREATED_AT,
    sort: queryParamsEnum.DESC,
  },
  {
    value: queryParamsEnum.ASC,
    label: 'INITIATIVES.SORT.DATE_ASC',
    type: queryParamsEnum.CREATED_AT,
    sort: queryParamsEnum.ASC,
  },
];

export const INITIATIVE_DEFAULT_IMAGES = [
  {
    url: getUrlWithCdn('initiative-default/default_1.jpg'),
    selected: false,
  },
  {
    url: getUrlWithCdn('initiative-default/default_2.jpg'),
    selected: false,
  },
  {
    url: getUrlWithCdn('initiative-default/default_3.jpg'),
    selected: false,
  },
  {
    url: getUrlWithCdn('initiative-default/default_4.jpg'),
    selected: false,
  },
  {
    url: getUrlWithCdn('initiative-default/default_5.jpg'),
    selected: false,
  },
  {
    url: getUrlWithCdn('initiative-default/default_6.jpg'),
    selected: false,
  },
  {
    url: getUrlWithCdn('initiative-default/default_7.jpg'),
    selected: false,
  },
];

export const allTypologies = [
  TypeInitiativeEnum.CHALLENGE_CANDIDATE,
  TypeInitiativeEnum.TRIGGER,
  TypeInitiativeEnum.DECLARATIVE,
  TypeInitiativeEnum.CHALLENGE_PROJECT,
] as string[];

export const allStatuses = [
  StatusInitiativeEnum.DRAFT,
  StatusInitiativeEnum.VALIDATED,
  StatusInitiativeEnum.IN_PROGRESS,
  StatusInitiativeEnum.CLOSED,
] as string[];

export const allSenderIds = [
  InitiativeSenderEnum.SENDER_PARTIAL,
  InitiativeSenderEnum.SENDER_ALL,
] as string[];
