import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from 'axios';
import { RequestStatusEnum } from 'core/enums/stateEnum';
import { IMe } from 'core/interfaces/account';
import { IResponseError } from 'core/interfaces/status';
import i18next from 'i18next';
import {
  getAccountsListDetail,
  patchAccountAffiliateAdmin,
  postAccountsRevokes,
  postAccountsActivates,
  postAccountsPasswordsReset,
  postAccountInitiatives,
  patchAccountDetailsAdmin,
  postAccountsUnActivate,
  postAccountsGivesWebAccess,
} from 'services/accountApi';
import { RootState } from './rootReducer';
import { setSnackBar } from './snackbarSlice';
import { IAccountsInventoryOptions } from 'core/interfaces/accountApi';

interface IContactState {
  items: IMe[];
  currentPage: number;
  total: number;
  selectedContacts: IMe[];
  loading: RequestStatusEnum;
  error: IResponseError | null;
}

const initialState: IContactState = {
  items: [],
  currentPage: 0,
  total: 0,
  selectedContacts: [],
  loading: RequestStatusEnum.IDLE,
  error: {} as IResponseError,
};

export const getAccountsListDetailThunk = createAsyncThunk(
  'contact/getAccountsListDetailThunk',
  async (options: IAccountsInventoryOptions, { rejectWithValue, dispatch }) => {
    try {
      const response = await getAccountsListDetail(options);
      return response.data;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

export const postContactsActivatesThunk = createAsyncThunk(
  'account/postContactsActivatesThunk',
  async (options: string[] | string, { rejectWithValue, dispatch }) => {
    try {
      const accountIds = typeof options === 'string' ? [options] : options;

      const response = await postAccountsActivates(accountIds);
      dispatch(
        setSnackBar({
          open: true,
          type: 'success',
          message: i18next.t('TRIGGERS.SUCCESS'),
        })
      );

      return response.data as IMe;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

export const postAccountsUnActivateThunk = createAsyncThunk(
  'account/postAccountsUnActivateThunk',
  async (options: string[] | string, { rejectWithValue, dispatch }) => {
    try {
      const accountIds = typeof options === 'string' ? [options] : options;

      const response = await postAccountsUnActivate(accountIds);
      dispatch(
        setSnackBar({
          open: true,
          type: 'success',
          message: i18next.t('TRIGGERS.SUCCESS'),
        })
      );

      return response.data as IMe;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

export const postContactsGivesWebAccessThunk = createAsyncThunk(
  'account/postContactsGivesWebAccessThunk',
  async (options: string[] | string, { rejectWithValue, dispatch }) => {
    try {
      const accountIds = typeof options === 'string' ? [options] : options;

      const response = await postAccountsGivesWebAccess(accountIds);
      dispatch(
        setSnackBar({
          open: true,
          type: 'success',
          message: i18next.t('TRIGGERS.SUCCESS'),
        })
      );

      return response.data as IMe;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

export const postAccountsReinitPasswordsThunk = createAsyncThunk(
  'account/postAccountsReinitPasswordsThunk',
  async (options: string[] | string, { rejectWithValue, dispatch }) => {
    try {
      const response = await postAccountsPasswordsReset(options);
      dispatch(
        setSnackBar({
          open: true,
          type: 'success',
          message: i18next.t('TRIGGERS.SUCCESS'),
        })
      );

      return response.data as IMe;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

export const postContactsRevokesThunk = createAsyncThunk(
  'account/postContactsRevokesThunk',
  async (options: string[] | string, { rejectWithValue, dispatch }) => {
    try {
      const accountIds = typeof options === 'string' ? [options] : options;
      const response = await postAccountsRevokes(accountIds);
      dispatch(
        setSnackBar({
          open: true,
          type: 'success',
          message: i18next.t('TRIGGERS.SUCCESS'),
        })
      );

      return response.data as IMe;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

export const postContactInitiativesThunk = createAsyncThunk(
  'account/postAccountInitiativesThunk',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await postAccountInitiatives();
      dispatch(
        setSnackBar({
          open: true,
          type: 'success',
          message: i18next.t('TRIGGERS.SUCCESS'),
        })
      );

      return response.data as IMe;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

export const patchAccountDetailsAdminThunk = createAsyncThunk(
  'account/patchAccountDetailsAdminThunk',
  async (
    user: Pick<IMe, 'accountId' | 'firstName' | 'lastName' | 'role'>,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await patchAccountDetailsAdmin(user);
      dispatch(
        setSnackBar({
          open: true,
          type: 'success',
          message: i18next.t('TRIGGERS.SUCCESS'),
        })
      );

      return response.data as IMe;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

export const patchAccountAffiliateAdminThunk = createAsyncThunk(
  'account/patchAccountAffiliateAdminThunk',
  async (user: Partial<IMe>, { rejectWithValue, dispatch }) => {
    try {
      const response = await patchAccountAffiliateAdmin(user);
      dispatch(
        setSnackBar({
          open: true,
          type: 'success',
          message: i18next.t('TRIGGERS.SUCCESS'),
        })
      );

      return response.data as IMe;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    resetSelectedContacts: (state: IContactState) => {
      state.selectedContacts = [];
    },
    addContact: (state: IContactState, { payload }: PayloadAction<IMe>) => {
      state.selectedContacts = [...state.selectedContacts, payload];
    },
    removeContact: (state: IContactState, { payload }: PayloadAction<IMe>) => {
      state.selectedContacts = state.selectedContacts.filter(
        (account) => account.accountId !== payload.accountId
      );
    },
    resetContacts: (state: IContactState) => {
      state.items = [];
    },
    setSelectedAccount: (state: IContactState, { payload }: PayloadAction<IMe>) => {
      state.selectedContacts = [payload];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAccountsListDetailThunk.pending, (state) => {
      state.loading = RequestStatusEnum.PENDING;
    });
    builder.addCase(getAccountsListDetailThunk.fulfilled, (state, action) => {
      state.loading = RequestStatusEnum.FULFILLED;
      state.total = action.payload.total;
      state.currentPage = action.payload.currentPage;
      if (state.currentPage === 0) {
        state.items = action.payload.items;
      } else {
        state.items = [...state.items, ...action.payload.items];
      }
    });
    builder.addCase(getAccountsListDetailThunk.rejected, (state, error) => {
      state.loading = RequestStatusEnum.REJECTED;
      state.error = error.payload as IResponseError;
    });
    builder.addCase(postContactInitiativesThunk.rejected, (state, error) => {
      state.error = error.payload as IResponseError;
    });
    builder.addCase(postContactInitiativesThunk.fulfilled, (state, action) => {
      const selectedAccount = state.selectedContacts[0];
      state.items = state.items.map((account) => {
        if (selectedAccount.accountId === account.accountId) {
          return action.payload as IMe;
        }
        return account;
      });
    });
    builder.addCase(patchAccountDetailsAdminThunk.rejected, (state, error) => {
      state.error = error.payload as IResponseError;
    });
    builder.addCase(patchAccountDetailsAdminThunk.fulfilled, (state, action) => {
      const selectedAccount = state.selectedContacts[0];
      state.items = state.items.map((account) => {
        if (selectedAccount.accountId === account.accountId) {
          return action.payload as IMe;
        }
        return account;
      });
      state.selectedContacts = [action.payload as IMe];
    });
    builder.addCase(patchAccountAffiliateAdminThunk.rejected, (state, error) => {
      state.error = error.payload as IResponseError;
    });
    builder.addCase(patchAccountAffiliateAdminThunk.fulfilled, (state, action) => {
      const selectedAccount = state.selectedContacts[0];
      state.items = state.items.map((account) => {
        if (selectedAccount.accountId === account.accountId) {
          return action.payload as IMe;
        }
        return account;
      });
      state.selectedContacts = [action.payload as IMe];
    });
  },
});

export const {
  resetSelectedContacts,
  addContact,
  removeContact,
  resetContacts,
  setSelectedAccount,
} = contactSlice.actions;

export const selectContact = (state: RootState) => state.contactReducer;

export default contactSlice.reducer;
