export enum DisplayStatusInitiativeEnum {
  DRAFT = 'INITIATIVES.STATUS_ADMIN.DRAFT',
  VALIDATED = 'INITIATIVES.STATUS_ADMIN.VALIDATED',
  IN_PROGRESS = 'INITIATIVES.STATUS_ADMIN.IN_PROGRESS',
  CLOSED = 'INITIATIVES.STATUS_ADMIN.CLOSED',
}

export enum StatusInitiativeEnum {
  DRAFT = 'DRAFT',
  VALIDATED = 'VALIDATED',
  IN_PROGRESS = 'IN_PROGRESS',
  CLOSED = 'CLOSED',
}

export enum TypeInitiativeEnum {
  CHALLENGE_CANDIDATE = 'CHALLENGE_CANDIDATE',
  TRIGGER = 'TRIGGER',
  DECLARATIVE = 'DECLARATIVE',
  CHALLENGE_PROJECT = 'CHALLENGE_PROJECT',
}

export enum DisplayTypeInitiativeEnum {
  CHALLENGE_CANDIDATE = 'CREATE_INITIATIVE.SELECT_INITIATIVE.CHALLENGE_CANDIDATE.TITLE',
  TRIGGER = 'CREATE_INITIATIVE.SELECT_INITIATIVE.TRIGGER.TITLE',
  DECLARATIVE = 'CREATE_INITIATIVE.SELECT_INITIATIVE.DECLARATIVE.TITLE',
  CHALLENGE_PROJECT = 'CREATE_INITIATIVE.SELECT_INITIATIVE.CHALLENGE_PROJECT.TITLE',
}

export enum DisplayShortTypeInitiativeEnum {
  CHALLENGE_CANDIDATE = 'CREATE_INITIATIVE.SELECT_INITIATIVE.CHALLENGE_CANDIDATE.SHORT_TITLE',
  TRIGGER = 'CREATE_INITIATIVE.SELECT_INITIATIVE.TRIGGER.SHORT_TITLE',
  DECLARATIVE = 'CREATE_INITIATIVE.SELECT_INITIATIVE.DECLARATIVE.SHORT_TITLE',
  CHALLENGE_PROJECT = 'CREATE_INITIATIVE.SELECT_INITIATIVE.CHALLENGE_PROJECT.SHORT_TITLE',
}

export enum TokenOriginEnum {
  INITIATIVE_PORTFOLIO = 'INITIATIVE_WALLET',
  EMPLOYEE_PORTFOLIO = 'USER_WALLET',
}

export enum SpendingRuleTypeEnum {
  FIXE = 'FIXE',
  VARIABLE = 'VARIABLE',
}

export enum LevelRuleEnum {
  LEVEL_ONE = 'LEVEL_ONE',
  LEVEL_TWO = 'LEVEL_TWO',
  LEVEL_THREE = 'LEVEL_THREE',
}

export enum RewardsReasonEnum {
  PARTICIPATION = 'PARTICIPATION',
  RANKING = 'RANKING',
  SPEED = 'SPEED',
  DECLARATIVE = 'DECLARATIVE',
  DECLARATION = 'DECLARATION',
  REPETITION = 'REPETITION',
}

export enum InitiativeGainsExpensesStatusEnum {
  VALID = 'VALID',
  INVALID = 'INVALID',
}

export enum InitiativeSenderEnum {
  SENDER_PARTIAL = 'PARTIAL',
  SENDER_ALL = 'ALL',
}
