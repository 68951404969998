import {
  ITriggerConfig,
  ITriggers,
  IRecurringTaskConfig,
  TriggerGroup,
  RecurringTaskGroup,
} from '../interfaces/trigger';
import TransferPurple from 'assets/pictograms/TransferPurple.svg';
import Notification from 'assets/pictograms/Notification.svg';
// import recurringTaskIconBirthDays from 'assets/pictograms/recurringTaskIconBirthDays.svg';
// import recurringTaskIconReminder from 'assets/pictograms/recurringTaskIconReminder.svg';
// import recurringTaskIconSlack from 'assets/pictograms/recurringTaskIconSlack.svg';
import recurringTaskIconTrigger from 'assets/pictograms/recurringTaskIconTrigger.svg';
import { RecurringTaskEnum, TriggersTypeEnum } from '../enums/triggerEnum';

export const triggersDefaultValues: ITriggers = {
  challengeCandidate: {
    nbVote: 5,
    nbWeeks: 12,
    tokenQuantity: 10,
    token: null,
    dayOfWeek: 3,
    time: '',
    activated: false,
    initialDateTime: null,
  },
  challengeProject: {
    nbVote: 5,
    nbWeeks: 3,
    tokenQuantity: 10,
    token: null,
    dayOfWeek: null,
    time: '',
    activated: false,
    initialDateTime: null,
  },
  onBoarding: {
    firstStep: {
      tokenQuantity: 1,
      token: null,
    },
    secondStep: {
      tokenQuantity: 1,
      token: null,
    },
    thirdStep: {
      tokenQuantity: 1,
      token: null,
    },
    activated: false,
  },
  peerToPeerRepetition: {
    nbTransfers: 8,
    nbWeeks: 1,
    tokenQuantity: 10,
    token: null,
    dayOfWeek: null,
    time: '',
    activated: false,
    initialDateTime: null,
  },
  regularTokenAllocation: {
    tokenQuantity: 20,
    nbWeeks: 4,
    token: null,
    dayOfWeek: 3,
    time: '',
    activated: false,
    initialDateTime: null,
  },
  weeklyReminder: {
    activated: false,
    dayOfWeek: null,
  },
};

export const recurringTaskConfigDefaultValues: Record<
  RecurringTaskGroup,
  { config: IRecurringTaskConfig[]; title: string }
> = {
  trigger: {
    config: [
      {
        title: 'V2_RECURRING_TASK.TRIGGER.INTENSITY_TRIGGER.TITLE',
        subTitle: 'V2_RECURRING_TASK.TRIGGER.INTENSITY_TRIGGER.SUB_TITLE',
        icon: recurringTaskIconTrigger,
        recurringTaskType: RecurringTaskEnum.INTENSITY_TRIGGER,
        boostName: 'intensity',
        data: {
          threshold: 1,
          rewardQuantity: 1,
          additionalRewardQuantity: 1,
        },
      },
      {
        title: 'V2_RECURRING_TASK.TRIGGER.REGULARITY_TRIGGER.TITLE',
        subTitle: 'V2_RECURRING_TASK.TRIGGER.REGULARITY_TRIGGER.SUB_TITLE',
        icon: recurringTaskIconTrigger,
        recurringTaskType: RecurringTaskEnum.REGULARITY_TRIGGER,
        boostName: 'regularity',
        data: {
          threshold: 1,
          rewardQuantity: 1,
          additionalRewardQuantity: 1,
        },
      },
      {
        title: 'V2_RECURRING_TASK.TRIGGER.INCLUSIVITY_TRIGGER.TITLE',
        subTitle: 'V2_RECURRING_TASK.TRIGGER.INCLUSIVITY_TRIGGER.SUB_TITLE',
        icon: recurringTaskIconTrigger,
        recurringTaskType: RecurringTaskEnum.INCLUSIVITY_TRIGGER,
        boostName: 'inclusion',
        data: {
          threshold: 1,
          rewardQuantity: 1,
          additionalRewardQuantity: 1,
        },
      },
    ],
    title: 'V2_RECURRING_TASK.TRIGGER.TITLE',
  },
  // reminder: {
  //   config: [
  //     {
  //       title: 'V2_RECURRING_TASK.REMINDER.HEBDO_REMINDER.TITLE',
  //       subTitle: 'V2_RECURRING_TASK.REMINDER.HEBDO_REMINDER.SUB_TITLE',
  //       icon: recurringTaskIconReminder,
  //       recurringTaskType: RecurringTaskEnum.REMINDER,
  //       data: {
  //         firstReminder: 'string',
  //         secondReminder: 'string',
  //         thirdReminder: 'string',
  //       },
  //     },
  //   ],
  //   title: 'V2_RECURRING_TASK.REMINDER.TITLE',
  // },
  // birthdays: {
  //   config: [
  //     {
  //       title: 'V2_RECURRING_TASK.BIRTHDAYS.BIRTHDAYS_COMPANY',
  //       icon: recurringTaskIconBirthDays,
  //       recurringTaskType: RecurringTaskEnum.BIRTHDAYS_COMPANY,
  //     },
  //     {
  //       title: 'V2_RECURRING_TASK.BIRTHDAYS.BIRTHDAYS_CIVIL',
  //       icon: recurringTaskIconBirthDays,
  //       recurringTaskType: RecurringTaskEnum.BIRTHDAYS_CIVIL,
  //     },
  //   ],
  //   title: 'V2_RECURRING_TASK.BIRTHDAYS.TITLE',
  // },
  // slack: {
  //   config: [
  //     {
  //       title: 'V2_RECURRING_TASK.SLACK.NEW_MEMBERS_RETRIEVAL',
  //       icon: recurringTaskIconSlack,
  //       recurringTaskType: RecurringTaskEnum.NEW_MEMBERS_RETRIEVAL,
  //     },
  //     {
  //       title: 'V2_RECURRING_TASK.SLACK.TOP_POST_NOTIFICATION',
  //       icon: recurringTaskIconSlack,
  //       recurringTaskType: RecurringTaskEnum.TOP_POST_NOTIFICATION,
  //     },
  //   ],
  //   title: 'V2_RECURRING_TASK.SLACK.TITLE',
  // },
};

export const triggersConfigDefaultValues: Record<
  TriggerGroup,
  { config: ITriggerConfig[]; title: string }
> = {
  regularUse: {
    config: [
      {
        title: 'TRIGGERS.REGULAR_USE.TITLE.ON_BOARDING',
        subTitle: 'TRIGGERS.REGULAR_USE.SUB_TITLE.ON_BOARDING',
        icon: TransferPurple,
        triggerType: TriggersTypeEnum.ON_BOARDING,
        triggerName: 'onBoarding',
        data: {
          firstStep: {
            tokenQuantity: 1,
            token: null,
          },
          secondStep: {
            tokenQuantity: 1,
            token: null,
          },
          thirdStep: {
            tokenQuantity: 1,
            token: null,
          },
          activated: false,
        },
      },
      {
        title: 'TRIGGERS.REGULAR_USE.TITLE.REGULAR_TOKEN_ALLOCATION',
        subTitle: 'TRIGGERS.REGULAR_USE.SUB_TITLE.REGULAR_TOKEN_ALLOCATION',
        icon: TransferPurple,
        triggerType: TriggersTypeEnum.REGULAR_TOKEN_ALLOCATION,
        triggerName: 'regularTokenAllocation',
        data: {
          tokenQuantity: 20,
          nbWeeks: 4,
          token: null,
          dayOfWeek: 3,
          time: '',
          activated: false,
          initialDateTime: null,
        },
      },
      {
        title: 'TRIGGERS.REGULAR_USE.TITLE.WEEKLY_REMINDER',
        subTitle: 'TRIGGERS.REGULAR_USE.SUB_TITLE.WEEKLY_REMINDER',
        icon: Notification,
        triggerType: TriggersTypeEnum.WEEKLY_REMINDER,
        triggerName: 'weeklyReminder',
        data: {
          dayOfWeek: 5,
          activated: false,
        },
      },
    ],
    title: 'TRIGGERS.TITLE_REGULAR_USE',
  },
  boostIndividualAndCollectiveRecognition: {
    config: [
      {
        title:
          'TRIGGERS.BOOST_INDIVIDUAL_AND_COLLECTIVE_RECOGNITION.TITLE.PEER_TO_PEER_RECOGNITION',
        subTitle:
          'TRIGGERS.BOOST_INDIVIDUAL_AND_COLLECTIVE_RECOGNITION.SUB_TITLE.PEER_TO_PEER_RECOGNITION',
        icon: TransferPurple,
        triggerType: TriggersTypeEnum.PEER_TO_PEER_REPETITION,
        triggerName: 'peerToPeerRepetition',
        data: {
          nbTransfers: 8,
          nbWeeks: 1,
          tokenQuantity: 10,
          token: null,
          dayOfWeek: null,
          time: '',
          activated: false,
          initialDateTime: null,
        },
      },
      {
        title: 'TRIGGERS.BOOST_INDIVIDUAL_AND_COLLECTIVE_RECOGNITION.TITLE.CHALLENGE_PROJECT',
        subTitle:
          'TRIGGERS.BOOST_INDIVIDUAL_AND_COLLECTIVE_RECOGNITION.SUB_TITLE.CHALLENGE_PROJECT',
        icon: TransferPurple,
        triggerType: TriggersTypeEnum.CHALLENGE_PROJECT,
        triggerName: 'challengeProject',
        data: {
          nbVote: 5,
          nbWeeks: 3,
          tokenQuantity: 10,
          token: null,
          dayOfWeek: null,
          time: '',
          activated: false,
          initialDateTime: null,
        },
      },
      {
        title: 'TRIGGERS.BOOST_INDIVIDUAL_AND_COLLECTIVE_RECOGNITION.TITLE.CHALLENGE_CANDIDATE',
        subTitle:
          'TRIGGERS.BOOST_INDIVIDUAL_AND_COLLECTIVE_RECOGNITION.SUB_TITLE.CHALLENGE_CANDIDATE',
        icon: TransferPurple,
        triggerType: TriggersTypeEnum.CHALLENGE_CANDIDATE,
        triggerName: 'challengeCandidate',
        data: {
          nbVote: 5,
          nbWeeks: 12,
          tokenQuantity: 10,
          token: null,
          dayOfWeek: 3,
          time: '',
          activated: false,
          initialDateTime: null,
        },
      },
    ],
    title: 'TRIGGERS.TITLE_BOOST_INDIVIDUAL_AND_COLLECTIVE_RECOGNITION',
  },
};
