import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import request from 'axios';
import { setSnackBar } from './snackbarSlice';
import i18next from 'i18next';
import { IResponseError } from '../core/interfaces/status';
import { fetchCharities } from '../services/charitiesApi';
import { RootState } from './rootReducer';
import { RequestStatusEnum } from '../core/enums/stateEnum';
import { ICharity, ICharitiesOptions } from '../core/interfaces/charity';

interface ICharitiesState {
  items: ICharity[];
  total: number;
  currentPage: number;
  error: IResponseError;
  loading: RequestStatusEnum;
}

const initialState: ICharitiesState = {
  items: [],
  total: 0,
  currentPage: 0,
  error: {} as IResponseError,
  loading: RequestStatusEnum.IDLE,
};

export const fetchCharitiesThunk = createAsyncThunk(
  'charities/fetchCharitiesThunk',
  async (options: ICharitiesOptions, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchCharities(options);

      return response.data;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

const charitiesSlice = createSlice({
  name: 'charities',
  initialState,
  reducers: {
    resetCharities: (state: ICharitiesState) => {
      state.items = [];
      state.total = 0;
      state.currentPage = 0;
      state.loading = RequestStatusEnum.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCharitiesThunk.pending, (state) => {
      state.loading = RequestStatusEnum.PENDING;
    });
    builder.addCase(fetchCharitiesThunk.fulfilled, (state, action) => {
      state.loading = RequestStatusEnum.FULFILLED;
      state.currentPage = action.payload.currentPage;
      state.total = action.payload.total;
      if (state.currentPage === 0) {
        state.items = action.payload.items;
      } else {
        state.items = [...state.items, ...action.payload.items];
      }
    });
    builder.addCase(fetchCharitiesThunk.rejected, (state, error) => {
      state.loading = RequestStatusEnum.REJECTED;
      state.error = error.payload as IResponseError;
    });
  },
});

export const { resetCharities } = charitiesSlice.actions;

export const selectCharities = (state: RootState) => state.charitiesReducer;

export default charitiesSlice.reducer;
