import axios, { AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios';
import { useErrorHandler } from './authInterceptorErrorsHandler';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL as string,
});

function IsTeamsTab() {
  if (window) {
    if (window.name === 'embedded-page-container' || window.name === 'extension-tab-frame') {
      return true;
    }
    const regex = new RegExp('TeamsMobile-iOS|TeamsMobile-Android', 'gi');
    return regex.test(window.navigator.userAgent);
  }
  return false;
}

axiosInstance.interceptors.request.use(
  async (request: InternalAxiosRequestConfig) => {
    if (IsTeamsTab()) {
      const idToken = localStorage.getItem('idtoken') as string;
      (request.headers as AxiosRequestHeaders).Authorization = `Bearer ${idToken}`;
    } else {
      const accessToken = localStorage.getItem('accessToken') as string;
      const accessTokenObject = JSON.parse(accessToken);
      // const isLoggedIn = Date.now() < accessTokenObject.expiration_date;
      if (accessTokenObject.id_token) {
        (
          request.headers as AxiosRequestHeaders
        ).Authorization = `Bearer ${accessTokenObject.access_token}`;
      }
    }

    return request;
  },
  (e) => {
    return Promise.reject(e);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (e) => {
    useErrorHandler.setState({ error: e });
    return Promise.reject(e);
  }
);

export default axiosInstance;
