import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatusEnum } from 'core/enums/stateEnum';
import { IResponseError } from 'core/interfaces/status';
import { RootState } from './rootReducer';
import { fetchAdminProductsProsumerHistory } from 'services/prosumerApi';
import request from 'axios';
import { setSnackBar } from './snackbarSlice';
import i18next from 'i18next';
import { IProsumerProductHistory } from 'core/interfaces/prosumer';
import { IAccountOptions } from 'core/interfaces/accountApi';

interface IProsumerHistoryState {
  items: IProsumerProductHistory[];
  total: number;
  loading: RequestStatusEnum;
  error: IResponseError;
  currentPage: number;
}

const initialState: IProsumerHistoryState = {
  items: [],
  total: 0,
  loading: RequestStatusEnum.IDLE,
  error: {} as IResponseError,
  currentPage: 0,
};

export const fetchAdminProductsProsumerHistoryThunk = createAsyncThunk(
  'prosumerHistory/fetchAdminProductsProsumerHistoryThunk',
  async (options: { id: string; params: IAccountOptions }, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchAdminProductsProsumerHistory(options.id, options.params);
      return response.data;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

const prosumerHistorySlice = createSlice({
  name: 'prosumerHistory',
  initialState,
  reducers: {
    resetProsumerHistory: (state: IProsumerHistoryState) => {
      state.items = [];
      state.total = 0;
      state.currentPage = 0;
      state.loading = RequestStatusEnum.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdminProductsProsumerHistoryThunk.pending, (state) => {
      state.loading = RequestStatusEnum.PENDING;
    });
    builder.addCase(fetchAdminProductsProsumerHistoryThunk.fulfilled, (state, action) => {
      state.loading = RequestStatusEnum.FULFILLED;
      state.total = action.payload.total;
      state.currentPage = action.payload.currentPage;
      if (state.currentPage === 0) {
        state.items = action.payload.items;
      } else {
        state.items = [...state.items, ...action.payload.items];
      }
    });
    builder.addCase(fetchAdminProductsProsumerHistoryThunk.rejected, (state, error) => {
      state.loading = RequestStatusEnum.REJECTED;
      state.error = error.payload as IResponseError;
    });
  },
});

export const { resetProsumerHistory } = prosumerHistorySlice.actions;

export const selectProsumerHistory = (state: RootState) => state.prosumerHistoryReducer;

export default prosumerHistorySlice.reducer;
