import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISnackBar } from '../core/interfaces/status';

import { RootState } from './rootReducer';

interface ISnackbarState {
  open: boolean;
  type: 'success' | 'error' | 'warning' | 'info';
  message: string;
  duration: number;
}

const initialState: ISnackbarState = {
  open: false,
  type: 'info',
  message: '',
  duration: 5000,
};

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    setSnackBar: (state, action: PayloadAction<ISnackBar>) => {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: {},
});

export const { setSnackBar } = snackbarSlice.actions;

export const selectSnackbar = (state: RootState) => state.snackbarReducer;

export default snackbarSlice.reducer;
