import { Box } from "@mui/material"
import DrawerDesktop from "features/common/drawerDesktop/DrawerDesktop"
import { Suspense } from "react"
import { Outlet } from "react-router-dom"

const DefaultAdminLayout = () => {
  return (
    <Box height="100%" display={'flex'}>
      <DrawerDesktop />
      <Box component="main" height="100%" flexGrow={1}>
        <Suspense>
          <Outlet />
        </Suspense>
      </Box>
    </Box>
  )
}

export default DefaultAdminLayout
