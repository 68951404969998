import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setSnackBar } from './snackbarSlice';
import request from 'axios';
import i18next from 'i18next';
import { IResponseError } from 'core/interfaces/status';
import { RequestStatusEnum } from 'core/enums/stateEnum';
import { RootState } from './rootReducer';
import { fetchAdminReward } from 'services/transferTokenApi';
import { IReward, IRewardOptions } from 'core/interfaces/transaction';

interface ITransferTokenState {
  items: IReward[];
  currentPage: number;
  total: number;
  loading: RequestStatusEnum;
  error: IResponseError | null;
}

const initialState: ITransferTokenState = {
  items: [],
  currentPage: 0,
  total: 0,
  loading: RequestStatusEnum.IDLE,
  error: {} as IResponseError,
};

export const fetchAdminRewardThunk = createAsyncThunk(
  'reward/fetchAdminRewardThunk',
  async (options: IRewardOptions, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchAdminReward(options);
      return response.data;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

const transferTokenSlice = createSlice({
  name: 'reward',
  initialState,
  reducers: {
    resetTransferToken: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdminRewardThunk.pending, (state) => {
      state.loading = RequestStatusEnum.PENDING;
    });
    builder.addCase(fetchAdminRewardThunk.fulfilled, (state, action) => {
      state.loading = RequestStatusEnum.FULFILLED;
      state.total = action.payload.total;
      state.currentPage = action.payload.currentPage;
      if (state.currentPage === 0) {
        state.items = action.payload.items;
      } else {
        state.items = [...state.items, ...action.payload.items];
      }
    });
    builder.addCase(fetchAdminRewardThunk.rejected, (state, error) => {
      state.loading = RequestStatusEnum.REJECTED;
      state.error = error.payload as IResponseError;
    });
  },
});

export const { resetTransferToken } = transferTokenSlice.actions;

export const selectTransferToken = (state: RootState) => state.transferTokenReducer;

export default transferTokenSlice.reducer;
