import axiosInstance from 'core/interceptors/authInterceptor';
import { IInitiativeProject, IInitiativeProjectPayload } from 'core/interfaces/initiativeProject';

const fetchInitiativeProject = async (id: number) => {
  return await axiosInstance.get(`/initiative/detail/${id}`);
};

const patchInitiativeProject = async (payload: IInitiativeProjectPayload) => {
  const response = await axiosInstance.patch(`/admin/initiative/${payload.initiativeId}`, {
    initiative: payload.initiative,
    step: payload.step,
  });
  return response;
};

const validateInitiativeProject = async (payload: {
  initiativeId: number;
  initiative: IInitiativeProject;
  step: number;
}) => {
  const response = await axiosInstance.patch(`/admin/initiative/${payload.initiativeId}`, {
    initiative: payload.initiative,
    step: payload.step,
  });
  return response;
};

export { fetchInitiativeProject, patchInitiativeProject, validateInitiativeProject };
