import axiosInstance from '../core/interceptors/authInterceptor';
import { queryBuilder } from '../core/helpers/apiHelpers';
import {
  IAccountingListOptions,
  ICreateAccountingItemPayload,
} from '../core/interfaces/accounting';
import { IPaginatedListParams } from '../core/interfaces/serviceApi';

const postAccountingItem = async (accountingItem: ICreateAccountingItemPayload) => {
  return await axiosInstance.post(`/accounting`, accountingItem);
};

const fetchAllAccounting = async (options: IAccountingListOptions) => {
  const queryOptions: IPaginatedListParams = {
    page: options.page,
    sort: options.sort,
    sortValue: options.sortValue,
    limit: options.limit,
  };
  const queryParams = queryBuilder(queryOptions);

  return await axiosInstance.post(`/accountings${queryParams}`, {
    companyIds: options.companyIds,
    direction: options.direction,
  });
};

export { fetchAllAccounting, postAccountingItem };
