import { lazy } from 'react';

export const Screens = {
  MarketplacePurchaseApprove: {
    path: '/marketplace/purchase/approve',
    Component: lazy(
      () =>
        import(
          'pages/admin/companiesSpace/prosumer/listProductProsumer/confirmPageProsumer/ConfirmPageProsumer'
        )
    ),
  },
  MarketplacePurchaseApproveId: {
    path: '/marketplace/purchase/approve/:productOrderCompanyId/:managerAccountId',
  },
  NotFound: {
    path: '/notFound',
    Component: lazy(() => import('pages/errorPages/NotFound')),
  },
  Denied: {
    path: '/denied',
    Component: lazy(() => import('pages/errorPages/ErrorDeniedPage')),
  },
  Revoked: {
    path: '/revoked',
    Component: lazy(() => import('pages/errorPages/ErrorRevoked')),
  },
  ErrorCompanyNotKnown: {
    path: '/error-company-not-known',
    Component: lazy(() => import('pages/errorPages/ErrorCompanyNotKnown')),
  },
  TokenMintId: {
    path: '/token/mint/:id',
    Component: lazy(
      () => import('pages/admin/companiesSpace/adminTokenInventory/mintToken/MintToken')
    ),
  },
  AdminProfile: {
    path: '/admin/profile',
    Component: lazy(() => import('pages/profile/Profile')),
  },
  AdminProfileEdit: {
    path: '/admin/profile/edit',
    Component: lazy(() => import('pages/profile/editProfile/EditProfile')),
  },
  AdminProfileNotifications: {
    path: '/admin/profile/notifications',
    Component: lazy(() => import('pages/profile/manageNotifications/ManageNotifications')),
  },
  AdminProfilePassword: {
    path: '/admin/profile/password',
    Component: lazy(() => import('pages/profile/editPassword/EditPassword')),
  },
  AdminProfileLanguage: {
    path: '/admin/profile/language',
    Component: lazy(() => import('pages/profile/editLanguage/EditLanguage')),
  },
  AdminHelpImprovement: {
    path: '/admin/help-improvement',
    Component: lazy(() => import('pages/impactCommunity/helpImprovement/HelpImprovement')),
  },
  AdminSuggestions: {
    path: '/admin/suggestions',
    Component: lazy(() => import('pages/impactCommunity/helpImprovement/Suggestions')),
  },
  InitiativeSelect: {
    path: '/initiative/select',
    Component: lazy(
      () =>
        import(
          'pages/admin/companiesSpace/initiativeList/create/selectInitiativeType/SelectInitiativeType'
        )
    ),
  },
  InitiativeSelectCompanyId: {
    path: ':companyId',
    Component: lazy(
      () =>
        import(
          'pages/admin/companiesSpace/initiativeList/create/selectInitiativeType/SelectInitiativeType'
        )
    ),
  },
  InitiativeCreateTypeId: {
    path: '/initiative/create/:type/:id',
    Component: lazy(
      () => import('pages/admin/companiesSpace/initiativeList/create/CreateInitiative')
    ),
  },
  InitiativeCreateTypeIdCompanyId: {
    path: ':companyId',
    Component: lazy(
      () => import('pages/admin/companiesSpace/initiativeList/create/CreateInitiative')
    ),
  },
  AdminAcrossCompaniesAccounting: {
    path: '/admin/across-companies/accounting',
    Component: lazy(
      () => import('pages/admin/acrossCompanies/accounting/AccountingAcrossCompanies')
    ),
  },
  AdminCompanies: {
    path: '/admin/companies',
    Component: lazy(() => import('pages/admin/companiesSpace/companiesList/CompaniesList')),
  },
  CompanyIdTokens: {
    path: ':companyId/tokens',
    Component: lazy(
      () => import('pages/admin/companiesSpace/adminTokenInventory/AdminTokenInventory')
    ),
  },
  CompanyIdAudiences: {
    path: ':companyId/audiences',
    Component: lazy(() => import('pages/admin/companiesSpace/audiences/Audiences')),
  },
  Collaborators: {
    path: 'collaborators',
    Component: lazy(() => import('pages/admin/companiesSpace/accountsInventory/AccountsInventory')),
  },
  Groups: {
    path: 'groups',
    Component: lazy(() => import('pages/admin/companiesSpace/audiences/groups/Groups')),
  },
  CompanyIdAudiencesGroupsCreate: {
    path: ':companyId/audiences/groups/create',
    Component: lazy(
      () => import('pages/admin/companiesSpace/audiences/groups/createGroups/CreateGroups')
    ),
  },
  CompanyIdAudiencesGroupsDetailsId: {
    path: ':companyId/audiences/groups/details/:id',
    Component: lazy(
      () => import('pages/admin/companiesSpace/audiences/groups/detailGroup/DetailGroup')
    ),
  },
  CompanyIdTokenCreate: {
    path: ':companyId/token/create',
    Component: lazy(
      () => import('pages/admin/companiesSpace/adminTokenInventory/createToken/CreateToken')
    ),
  },
  CompanyIdTriggers: {
    path: ':companyId/triggers',
    Component: lazy(() => import('pages/admin/companiesSpace/triggersList/TriggersList')),
  },
  CompanyIdFunctionalities: {
    path: ':companyId/functionalities',
    Component: lazy(
      () => import('pages/admin/companiesSpace/adminFunctionalities/AdminFunctionalities')
    ),
  },
  CompanyIdInitiatives: {
    path: ':companyId/initiatives',
    Component: lazy(() => import('pages/admin/companiesSpace/votesCompany/InitiativesAdmin')),
  },
  CompanyIdBirthdays: {
    path: ':companyId/birthdays',
    Component: lazy(() => import('pages/admin/companiesSpace/birthDaysCompany/BirthDaysCompany')),
  },
  CompanyIdDashboard: {
    path: ':companyId/dashboard',
    Component: lazy(() => import('pages/admin/companiesSpace/dashboardCompany/DashboardCompany')),
  },
  CompanyIdGiftCards: {
    path: ':companyId/gift-cards',
    Component: lazy(() => import('pages/admin/companiesSpace/giftCards/GiftCards')),
  },
  CompanyIdLotteries: {
    path: ':companyId/lotteries',
    Component: lazy(() => import('pages/admin/companiesSpace/raffleByCompany/RaffleByCompany')),
  },
  CompanyIdCreateChallenge: {
    path: ':companyId/create/challenge',
    Component: lazy(() => import('pages/admin/companiesSpace/createChallenge/CreateChallenge')),
  },
  CompanyIdProsumer: {
    path: ':companyId/prosumer',
    Component: lazy(
      () => import('pages/admin/companiesSpace/prosumer/listProductProsumer/ListProductProsumer')
    ),
  },
  CompanyIdCharities: {
    path: ':companyId/charities',
    Component: lazy(
      () => import('pages/admin/companiesSpace/charitiesByCompany/CharitiesByCompany')
    ),
  },
  CompanyIdLotteriesLaunchId: {
    path: ':companyId/lotteries/launch/:id',
    Component: lazy(() => import('features/raffle/raffleLaunch/RaffleLaunch')),
  },
  CompanyIdTransfer: {
    path: ':companyId/transfer',
    Component: lazy(
      () => import('pages/admin/companiesSpace/transferToken/transferByCompany/TransferByCompany')
    ),
  },
  CompanyIdTransferToken: {
    path: ':companyId/transfer/token',
    Component: lazy(() => import('pages/admin/companiesSpace/transferToken/TransferToken')),
  },
  CompanyIdTransferTokenCollaborators: {
    path: 'collaborators',
    Component: lazy(
      () =>
        import(
          'pages/admin/companiesSpace/transferToken/transferByCompany/transferToken/TransferTokenByCompany'
        )
    ),
  },
  CompanyIdTransferTokenCollaboratorsGroupsGroupId: {
    path: 'groups/:groupId?',
    Component: lazy(() => import('pages/admin/companiesSpace/transferToken/TransferToKenToGroup')),
  },
  CompanyIdIntegration: {
    path: ':companyId/integration',
    Component: lazy(
      () => import('pages/admin/companiesSpace/integrationConfiguration/IntegrationConfiguration')
    ),
  },
  CompanyIdCreateProsumer: {
    path: ':companyId/create/prosumer',
    Component: lazy(
      () => import('pages/admin/companiesSpace/prosumer/createProsumer/CreateProsumer')
    ),
  },
  CompanyIdUpdateProsumerId: {
    path: ':companyId/update/prosumer/:id',
    Component: lazy(
      () => import('pages/admin/companiesSpace/prosumer/createProsumer/CreateProsumer')
    ),
  },
  CultureElement: {
    path: ':companyId/culture-element',
    Component: lazy(
      () => import('pages/admin/companiesSpace/cultureElementByCompany/CultureElementByCompany')
    ),
  },
  CultureElementAborescence: {
    path: 'aborescence',
    Component: lazy(
      () => import('features/cultureElement/cultureElementAborescence/CultureElementAborescence')
    ),
  },
  CultureElementEdit: {
    path: 'edit',
    Component: lazy(() => import('features/cultureElement/cultureElementEdit/CultureElementEdit')),
  },
  CreateCultureElement: {
    path: ':companyId/create/culture-element',
    Component: lazy(
      () =>
        import(
          'features/cultureElement/cultureElementEdit/createCultureElement/CreateCultureElement'
        )
    ),
  },
  UpdateCultureElement: {
    path: ':companyId/culture-element/:id',
    Component: lazy(
      () =>
        import(
          'features/cultureElement/cultureElementEdit/createCultureElement/CreateCultureElement'
        )
    ),
  },
  ModuleRecognition: {
    path: ':companyId/module/recognition',
    Component: lazy(
      () => import('pages/admin/companiesSpace/recognizeByCompany/ModuleRecognitionByCompany')
    ),
  },
  ModuleRecognitionParams: {
    path: 'params',
    Component: lazy(() => import('features/moduleRecognition/recognitionParams/RecognitionParams')),
  },
  ModuleRecurringTask: {
    path: 'recurringTask',
    Component: lazy(() => import('features/moduleRecognition/recurringTask/RecurringTask')),
  },
};
