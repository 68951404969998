export enum TriggersTypeEnum {
  CHALLENGE_CANDIDATE = 'CHALLENGE_CANDIDATE',
  DECLARATIVE = 'DECLARATIVE',
  CHALLENGE_PROJECT = 'CHALLENGE_PROJECT',
  ON_BOARDING = 'ON_BOARDING',
  REGULAR_TOKEN_ALLOCATION = 'REGULAR_TOKEN_ALLOCATION',
  PEER_TO_PEER_REPETITION = 'PEER_TO_PEER_REPETITION',
  WEEKLY_REMINDER = 'WEEKLY_REMINDER',
  PEER_TO_PEER_RECOGNITION_ACTIONS = 'PEER_TO_PEER_RECOGNITION_ACTIONS',
}

export enum TriggerOnBoardingStepEnum {
  FIRST_STEP = 'FIRST_STEP',
  SECOND_STEP = 'SECOND_STEP',
  THIRD_STEP = 'THIRD_STEP',
}

export enum RecurringTaskEnum {
  INTENSITY_TRIGGER = 'INTENSITY_TRIGGER',
  REGULARITY_TRIGGER = 'REGULARITY_TRIGGER',
  INCLUSIVITY_TRIGGER = 'INCLUSIVITY_TRIGGER',
  REMINDER = 'REMINDER',
  BIRTHDAYS_COMPANY = 'BIRTHDAYS_COMPANY',
  BIRTHDAYS_CIVIL = 'BIRTHDAYS_CIVIL',
  NEW_MEMBERS_RETRIEVAL = 'NEW_MEMBERS_RETRIEVAL',
  TOP_POST_NOTIFICATION = 'TOP_POST_NOTIFICATION',
  RECOGNITION = 'RECOGNITION',
  RECOGNITION_ADMIN = 'RECOGNITION_ADMIN',
  EXTRACT_DATA = 'EXTRACT_DATA',
  BOOST = 'BOOST',
  CHALLENGE = 'CHALLENGE',
}
