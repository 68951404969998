import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatusEnum } from 'core/enums/stateEnum';
import { IResponseError } from 'core/interfaces/status';
import { RootState } from './rootReducer';
import { fetchAdminAllProductsProsumer } from 'services/prosumerApi';
import request from 'axios';
import { setSnackBar } from './snackbarSlice';
import i18next from 'i18next';
import { IProsumerProductData } from 'core/interfaces/prosumer';
import { IAccountOptions } from 'core/interfaces/accountApi';

interface IProsumerState {
  items: IProsumerProductData[];
  total: number;
  loading: RequestStatusEnum;
  error: IResponseError;
  currentPage: number;
}

const initialState: IProsumerState = {
  items: [],
  total: 0,
  loading: RequestStatusEnum.IDLE,
  error: {} as IResponseError,
  currentPage: 0,
};

export const fetchAdminAllProductsProsumerThunk = createAsyncThunk(
  'prosumer/fetchAdminAllProductsProsumerThunk',
  async (options: IAccountOptions, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchAdminAllProductsProsumer(options);
      return response.data;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

const prosumerSlice = createSlice({
  name: 'prosumer',
  initialState,
  reducers: {
    resetProsumers: (state: IProsumerState) => {
      state.items = [];
      state.total = 0;
      state.currentPage = 0;
      state.loading = RequestStatusEnum.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdminAllProductsProsumerThunk.pending, (state) => {
      state.loading = RequestStatusEnum.PENDING;
    });
    builder.addCase(fetchAdminAllProductsProsumerThunk.fulfilled, (state, action) => {
      state.loading = RequestStatusEnum.FULFILLED;
      state.total = action.payload.total;
      state.currentPage = action.payload.currentPage;
      if (state.currentPage === 0) {
        state.items = action.payload.items;
      } else {
        state.items = [...state.items, ...action.payload.items];
      }
    });
    builder.addCase(fetchAdminAllProductsProsumerThunk.rejected, (state, error) => {
      state.loading = RequestStatusEnum.REJECTED;
      state.error = error.payload as IResponseError;
    });
  },
});

export const { resetProsumers } = prosumerSlice.actions;

export const selectProsumers = (state: RootState) => state.prosumerReducer;

export default prosumerSlice.reducer;
